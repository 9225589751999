export const AREA_TYPES = [
  {
    label: 'Andheri East',
    value: 'ANDHERI_EAST'
  },
  {
    label: 'Andheri West',
    value: 'ANDHERI_WEST'
  },
  {
    label: 'Jogeshwari East',
    value: 'JOGESHWARI_EAST'
  },
  {
    label: 'Jogeshwari West',
    value: 'JOGESHWARI_WEST'
  },
  {
    label: 'Vile Parle East',
    value: 'VILE_PARLE_EAST'
  },
  {
    label: 'Vile Parle West',
    value: 'VILE_PARLE_WEST'
  },
  {
    label: 'Bandra East',
    value: 'BANDRA_EAST'
  },
  {
    label: 'Bandra West',
    value: 'BANDRA_WEST'
  },
  {
    label: 'Malad East',
    value: 'MALAD_EAST'
  },
  {
    label: 'Malad West',
    value: 'MALAD_WEST'
  },
  {
    label: 'Prabhadevi East',
    value: 'PRABHADEVI_EAST'
  },
  {
    label: 'Prabhadevi West',
    value: 'PRABHADEVI_WEST'
  },
  {
    label: 'Goregaon East',
    value: 'GOREGAON_EAST'
  },
  {
    label: 'Goregaon West',
    value: 'GOREGAON_WEST'
  },
  {
    label: 'Dadar East',
    value: 'DADAR_EAST'
  },
  {
    label: 'Dadar West',
    value: 'DADAR_WEST'
  },
  {
    label: 'Borivali East',
    value: 'BORIVALI_EAST'
  },
  {
    label: 'Borivali West',
    value: 'BORIVALI_WEST'
  },
  {
    label: 'Bhayandar East',
    value: 'BHAYANDAR_EAST'
  },
  {
    label: 'Bhayandar West',
    value: 'BHAYANDAR_WEST'
  },
  {
    label: 'Colaba',
    value: 'COLABA'
  },
  {
    label: 'Lower Parel',
    value: 'LOWER_PAREL'
  },
  {
    label: 'Worli',
    value: 'WORLI'
  },
  {
    label: 'Grant Road East',
    value: 'GRANT_ROAD_EAST'
  },
  {
    label: 'Grant Road West',
    value: 'GRANT_ROAD_WEST'
  },
  {
    label: 'Kandivali East',
    value: 'KANDIVALI_EAST'
  },
  {
    label: 'Kandivali West',
    value: 'KANDIVALI_WEST'
  }
];
