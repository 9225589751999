import { useLocation } from 'react-router-dom';
import {
  createRestaurantSubscription,
  getRestaurantSubscription
} from '../../actions/actions';
import { getPageTexts } from './Subscription.helpers';
import { useEffect, useState } from 'react';
import { useNoGlobalHeader } from '../../global/useNoGlobalHeader';
import { RestaurantSubscriptionDetailsApiSchema } from '../../schema.types';

export const useSubscription = () => {
  const location = useLocation();
  const resId = location.state?.resId;

  const [resSubs, setResSubs] =
    useState<RestaurantSubscriptionDetailsApiSchema>(
      {} as RestaurantSubscriptionDetailsApiSchema
    );
  const [loading, setLoading] = useState(true);
  const [isPreselected, setIsPreselected] = useState(false);
  const [pageHighlightText, setPageHighlightText] = useState('');
  const [selectedPlan, setSelectedPlan] = useState<
    RestaurantSubscriptionDetailsApiSchema['selectedPlan'] | null
  >(null);

  useNoGlobalHeader();

  useEffect(() => {
    (async () => {
      const resSubData = await getRestaurantSubscription(resId);
      setResSubs(resSubData);

      if (resSubData.selectedPlan) {
        setIsPreselected(true);
        setSelectedPlan(resSubData.selectedPlan);
      }

      setLoading(false);
    })();
  }, [resId]);

  const onSubmit = async () => {
    if (!resSubs) return; // TODO: Handle error when resSubs is undefined!

    const subscriptionLink = resSubs.subscription?.subscriptionLink;
    if (subscriptionLink) {
      setPageHighlightText('Refresh this page once the payment is completed.');

      // 1. If the subscription link is already present, then open new window with the link
      window.open(subscriptionLink, '_blank');
    } else if (selectedPlan) {
      // 2. If the subscription link is not present, first create a new subscription and then open the new window with the link
      const subscriptionRedirectData = await createRestaurantSubscription(
        resId,
        {
          planId: selectedPlan.id
        }
      );
      setPageHighlightText('Refresh this page once the payment is completed.');
      window.open(subscriptionRedirectData.subscriptionLink, '_blank');
    }
  };

  const isSubscribed = resSubs?.subscription?.isSubscribed;
  const { title, subTitle, submitButtonText, isSuccessStateOnPage } =
    getPageTexts({
      availablePlans: resSubs?.availablePlans,
      subscription: resSubs?.subscription,
      paymentStatus: resSubs?.paymentStatus
    });

  return {
    resSubs,
    loading,
    isSubscribed,
    onSubmit,
    title,
    subTitle,
    submitButtonText,
    isSuccessStateOnPage,
    pageHighlightText,
    selectedPlan,
    isPreselected,
    setSelectedPlan
  };
};
