import { useCallback, useRef, useState } from 'react';
import { ResItemProps } from '../ManagementPanel.types';
import { updateRestaurantFromManagementPanel } from '../../../actions/actions';
import { useOutsideClick } from '../../../global/useOutsideClick';
import {
  OptionItem,
  OptionsCta,
  OptionsDropdown
} from '../ManagementPanel.styles';
import { SlOptionsVertical } from 'react-icons/sl';

export const useResItem = ({
  restaurant,
  optionsConfig
}: {
  restaurant: ResItemProps['restaurant'];
  optionsConfig: ResItemProps['optionsConfig'];
}) => {
  const { options, onSelect } = optionsConfig;

  const [currentRes, setCurrentRes] = useState(restaurant);
  const [isEditing, setIsEditing] = useState(false);

  const optionsPopupRef = useRef(null);
  const { isOpen, togglePopup } = useOutsideClick(optionsPopupRef);

  const onEdit = () => {
    setIsEditing(true);
  };

  const onSave = async () => {
    setIsEditing(false);

    const {
      resId,
      state: { state, remarks },
      planCategory: { id: planCategoryId }
    } = currentRes;

    const updatedRes = await updateRestaurantFromManagementPanel({
      resId,
      state,
      planCategoryId,
      remarks
    });
    setCurrentRes(updatedRes);
  };

  const renderOptions = useCallback(() => {
    return (
      <OptionsCta ref={optionsPopupRef}>
        <SlOptionsVertical onClick={() => togglePopup(!isOpen)} />
        <OptionsDropdown isVisible={isOpen}>
          {options?.map(option => (
            <OptionItem
              key={option.value}
              onClick={() => {
                togglePopup(false);
                onSelect(option.value, restaurant);
              }}
            >
              {option.label}
            </OptionItem>
          ))}
        </OptionsDropdown>
      </OptionsCta>
    );
  }, [isOpen, onSelect, options, restaurant, togglePopup]);

  return {
    currentRes,
    isEditing,
    onEdit,
    onSave,
    setCurrentRes,
    setIsEditing,
    renderOptions
  };
};
