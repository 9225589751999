import { styled } from 'styled-components';
import { TEXT_INPUT_THEMES } from './TextInput.types';
import { COLORS } from '../../common/theme';

export const StyledInputWrapper = styled.div<{
  noMargin?: boolean;
  isDisabled?: boolean;
  theme: TEXT_INPUT_THEMES;
}>`
  ${({ theme, noMargin }) => {
    switch (theme) {
      case TEXT_INPUT_THEMES.DEFAULT:
        return `height: 50px; margin: ${noMargin ? '0' : '20px 0'};`;
      case TEXT_INPUT_THEMES.SEARCH:
        return `height: 40px; border-radius: 2px; margin: 10px 0;`;
      default:
        return getDefaultThemeStyling();
    }
  }}

  position: relative;
  display: flex;
  flex-direction: column;
  ${({ isDisabled }) =>
    isDisabled ? 'opacity: 0.5; pointer-events: none;' : ''}

  > * {
    flex: 1;
  }

  .search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: ${COLORS.primaryBlue};
  }
`;

const getDefaultThemeStyling = () => {
  return `  
    outline: none;
    border: none;
    border-bottom: 1px solid lightgrey;
    width: 100%;
    height: 30px;
    font-size: 18px;
    
    &:focus {
      border-bottom: 1px solid black;
    }
  `;
};

const getSearchThemeStyling = () => {
  return `  
    outline: none;
    border: none;
    width: 100%;
    height: 30px;
    font-size: 18px;
    border-radius: 2px;
    padding: 5px;
    background-color: ${COLORS.gray4};
    color: ${COLORS.primaryBlue}
  `;
};

export const StyledInput = styled.input<{
  theme: TEXT_INPUT_THEMES;
  error: boolean;
}>`
  ${({ theme }) => {
    switch (theme) {
      case TEXT_INPUT_THEMES.DEFAULT:
        return getDefaultThemeStyling();
      case TEXT_INPUT_THEMES.SEARCH:
        return getSearchThemeStyling();
      default:
        return getDefaultThemeStyling();
    }
  }}

  ${({ error }) => error && 'border-bottom: 1px solid red;'}
  &:focus {
    ${({ error }) => error && 'border-bottom: 1px solid red;'}
  }
`;
