import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from './routes';

const AppRoutes = () => {
  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default AppRoutes;
