import { useState } from 'react';
import {
  ListItem,
  MenuItemContent,
  MenuListCard,
  MenuListWrapper,
  MenuItemFooter
} from './MenuList.styles';
import { FaIndianRupeeSign } from 'react-icons/fa6';
import { useCommonActions } from '../../../store/commonStore';
import { FaStar } from 'react-icons/fa';
import { DISH_TYPES } from '../MenuForm/MenuForm.constants';

const MenuList = ({
  menu,
  onChange,
  onEdit
}: {
  menu: any[];
  onChange: (menu: any[]) => void;
  onEdit: (obj: { item: any; index: number }) => void;
}) => {
  const { setSnackbarMessage } = useCommonActions();

  const onDelete = (index: number) => {
    menu.splice(index, 1);
    onChange([...menu]);
    setSnackbarMessage('Item deleted successfully!');
  };

  const renderItemContent = (item: any) => {
    return (
      <MenuItemContent>
        <div>
          <ListItem isLarge>{item.name}</ListItem>
          <ListItem>{item.category}</ListItem>
        </div>
        <ListItem>
          {DISH_TYPES.find(type => type.value === item.dishType)?.label}
        </ListItem>
        <ListItem>{item.isBestSeller ? <FaStar /> : <></>}</ListItem>
        <ListItem isLarge className="price">
          <FaIndianRupeeSign size={20} /> {item.price}
        </ListItem>
      </MenuItemContent>
    );
  };

  const renderItemFooter = (index: number) => {
    return (
      <MenuItemFooter>
        <div
          onClick={() =>
            onEdit({
              item: menu[index],
              index
            })
          }
        >
          Edit
        </div>
        <div onClick={() => onDelete(index)}>Delete</div>
      </MenuItemFooter>
    );
  };

  return (
    <MenuListWrapper>
      {menu.map((item, index) => (
        <>
          <MenuListCard>
            {renderItemContent(item)}
            {renderItemFooter(index)}
          </MenuListCard>
        </>
      ))}
    </MenuListWrapper>
  );
};

export default MenuList;
