import { styled } from 'styled-components';
import { COLORS } from '../../common/theme';

export const TimePickerContainer = styled.div`
  .timings {
    display: flex;
    align-items: center;

    select {
      flex: 1;
      outline: none;
      border: 1px solid ${COLORS.primaryBlue};
      margin: 0 5px;
      text-align: right;
      padding: 5px;
      border-radius: 4px;
    }
  }
`;
