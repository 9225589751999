import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCommonActions, useCommonStates } from '../../../store/commonStore';
import { addRestaurant, saveRestaurantDetails } from '../../../actions/actions';
import { getPayload } from './addRestaurent.helpers';
import { getSelectableList } from '../../../common/utils';
import { APP_ROUTES } from '../../../common/constants';

const useAddRestaurantStates = () => {
  const location = useLocation();
  const resChoices = useCommonStates().resChoices;
  const { setPageTitle } = useCommonActions();
  const navigate = useNavigate();

  const { setSnackbarMessage, setCuisines: setCuisinesList } =
    useCommonActions();

  const editedRestaurant = location.state?.restaurant || {};
  const editedRestaurantId = location.state?.restaurant?.resId || '';
  const isNewResAdditionFlow = !editedRestaurantId;

  // Page level states
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<any>({});

  // Basic details
  const [name, setName] = useState({
    value: editedRestaurant.resName || '',
    error: ''
  });
  const [referredBy, setReferredBy] = useState({
    value: editedRestaurant.referredBy || '',
    error: ''
  });
  const [gmapLink, setGmapLink] = useState({
    value: editedRestaurant.location?.gmapLink || '',
    error: ''
  });
  const [fullAddress, setFullAddress] = useState({
    value: editedRestaurant.location?.fullAddress || '',
    error: ''
  });

  const defaultAreaName = getSelectableList([
    editedRestaurant.location?.areaName || ''
  ])[0];
  const [areaName, setAreaName] = useState(defaultAreaName);

  const defaultCuisines = getSelectableList(editedRestaurant.cuisines || []);
  const [cuisines, setCuisines] = useState<any[]>(defaultCuisines);

  const [avgPriceForOne, setAvgPriceForOne] = useState({
    value: editedRestaurant.avgPrice,
    error: ''
  });

  // Manager/Owner details
  const [phoneNumbers, setPhoneNumbers] = useState<any[]>(
    editedRestaurant.phoneNumbers && editedRestaurant.phoneNumbers.length > 0
      ? editedRestaurant.phoneNumbers.map((n: string) => ({
          value: n,
          error: false
        }))
      : [{ value: '', error: true }]
  );
  const [isManagedByOwner, setIsManagedByOwner] = useState(
    editedRestaurant.isManagedByOwner || false
  );

  // Timings and Facilities
  const [restaurantTimings, setRestaurantTimings] = useState(
    editedRestaurant.restaurantTimings &&
      editedRestaurant.restaurantTimings.length > 0
      ? editedRestaurant.restaurantTimings
      : [{ startTime: '00:00', endTime: '00:00' }]
  );
  const [dineInDetails, setDineInDetails] = useState(
    editedRestaurant.dineIn || {
      enabled: false
    }
  );
  const [takeawayDetails, setTakeawayDetails] = useState(
    editedRestaurant.takeaway || {
      enabled: false
    }
  );

  const [deliveryDetails, setDeliveryDetails] = useState(
    editedRestaurant.delivery || {
      enabled: false
    }
  );

  const [resImage, setResImage] = useState(editedRestaurant.resImage || '');

  const addNewItemToCuisines = (values: any) => {
    const latestItem = values[values.length - 1];
    if (latestItem?.__isNew__) {
      setCuisinesList([...(resChoices?.cuisines || []), latestItem.value]);
    }
    setCuisines(values);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    const states = {
      name,
      cuisines,
      dineInDetails,
      takeawayDetails,
      deliveryDetails,
      gmapLink,
      fullAddress,
      areaName,
      avgPriceForOne,
      phoneNumbers,
      isManagedByOwner,
      referredBy,
      restaurantTimings,
      resImage
    };

    const { error, payload } = getPayload(states);
    if (error) {
      setSnackbarMessage(error);
      setLoading(false);
      return;
    }

    /**
     * Add/Save restaurant details
     */
    try {
      if (editedRestaurant) {
        await saveRestaurantDetails(payload, editedRestaurantId);
      } else {
        await addRestaurant(payload);
      }
    } catch (err) {
      setSnackbarMessage(
        editedRestaurant
          ? 'Error updating restaurant'
          : 'Error adding restaurant'
      );
    } finally {
      setLoading(false);
    }

    if (editedRestaurant) {
      // Edit flow
      navigate(APP_ROUTES.restaurants, { replace: true });
    } else {
      // Add flow
      navigate(APP_ROUTES.menu, { replace: true });
    }
  };

  useEffect(() => {
    const isEdit = editedRestaurant?.resId;
    setPageTitle(isEdit ? 'Edit Restaurant' : 'Add Restaurant');
    return () => {
      setPageTitle('');
    };
  }, [editedRestaurant?.resId, setPageTitle]);

  return {
    states: {
      name,
      cuisines,
      avgPriceForOne,
      phoneNumbers,
      isManagedByOwner,
      dineInDetails,
      takeawayDetails,
      deliveryDetails,
      validationErrors,
      loading,
      gmapLink,
      fullAddress,
      areaName,
      restaurantTimings,
      isNewResAdditionFlow,
      referredBy,
      resImage,
      cuisinesList: resChoices?.cuisines || []
    },
    actions: {
      setReferredBy,
      setAreaName,
      setFullAddress,
      setName,
      setGmapLink,
      setCuisines,
      setAvgPriceForOne,
      setPhoneNumbers,
      setIsManagedByOwner,
      setDineInDetails,
      setTakeawayDetails,
      setDeliveryDetails,
      setCuisinesList,
      setValidationErrors,
      setLoading,
      onSubmit,
      addNewItemToCuisines,
      setRestaurantTimings,
      setResImage
    }
  };
};

export default useAddRestaurantStates;
