import { InputTypes } from './TextInput.types';

export const checkIfValidInput = (value: any, inputType?: InputTypes) => {
  switch (inputType) {
    case InputTypes.MOBILE: {
      const mobilePattern = /^[6-9]\d{9}$/;
      const landlinePattern = /^\d{11}$/;
      const isValidMobile = mobilePattern.test(value);
      const isValidLandline = landlinePattern.test(value);

      return isValidMobile || isValidLandline ? '' : `Invalid mobile number`;
    }
    case InputTypes.OTP: {
      const otpPattern = /^\d{6}$/;
      const isValidOtp = value.match(otpPattern);
      return isValidOtp ? '' : `Invalid OTP`;
    }
    case InputTypes.NUMBER: {
      const numberPattern = /^\d+$/;
      const isValidNumber = value.match(numberPattern);
      return isValidNumber ? '' : `Invalid number`;
    }
    default:
      return '';
  }
};
