import { styled } from 'styled-components';
import { COLORS } from '../../common/theme';

export const AddRestaurantWrapper = styled.div`
  padding: 10px;
  height: 100%;
  position: relative;

  form {
    height: 100%;
    overflow-y: auto;
  }
`;

export const TimingsWrapper = styled.div`
  margin-bottom: 20px;

  > label {
    display: block;
    font-size: 18px;
  }

  .timingsDiv {
    display: flex;

    .timingInputs {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }
`;

export const PhoneNumbersWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;

  > div:first-child {
    flex: 1;
  }
`;

export const ImageSelectorContainer = styled.div`
  display: flex;
  gap: 5px;
  margin: 20px 0;
  align-items: center;

  .imageSelectorBtn {
    flex: 1;
    color: ${COLORS.primaryBlue};
    text-decoration: underline;
  }
`;

export const SelectedImageTag = styled.img`
  width: 60px;
  height: auto;
`;

export const ImagesPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.white};
  height: 100%;
`;

export const ImagesPreview = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex: 1;
  gap: 10px;
  overflow-y: auto;
  padding: 10px;

  .preview-img {
    position: relative;

    img {
      width: 100%;
      height: 150px;
      display: block;
      border: 1px solid ${COLORS.gray2};
      border-radius: 5px;

      &.selected {
        border: 5px solid ${COLORS.primaryBlue};
      }
    }

    .selected-icon {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
`;

export const ActionButtons = styled.div`
  align-self: end;
  display: flex;
  gap: 5px;
  width: 100%;
  padding: 10px;

  > * {
    flex: 1;
  }
`;
