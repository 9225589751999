const COLORS = {
  primaryBlue: '#00406A',
  lightBlue: '#0084DC',
  lighterBlue: '#D9F0FF',

  black: '#000000',
  white: '#FFFFFF',

  yellow: '#DC9E00',
  green: '#036B14',
  red: '#920000',
  orange: '#fc5a03',

  gray1: '#E0E0E0',
  gray2: '#B9B9B9',
  gray3: '#7C7C7C',
  gray4: '#f1f1f1',

  transparent: 'transparent'
};

const SIZES = {
  xxSmall: 10,
  xSmall: 12,
  small: 14,
  medium: 16,
  large: 20,
  xLarge: 24,
  xxLarge: 32
};

const FONT_WEIGHTS = {
  light: '300',
  regular: '400',
  medium: '500',
  bold: '700'
};

export { COLORS, SIZES, FONT_WEIGHTS };
