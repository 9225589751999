import { useCallback, useEffect, useState } from 'react';
import {
  getAllRestaurantsForManagement,
  getManagementChoices,
  getPlanCategories,
  uploadRestaurantMenuExcel
} from '../../actions/actions';
import { useUserStates } from '../../store/userStore';
import {
  RestaurantListApiSchema,
  RestaurantMenuUploadExcelApiSchema
} from '../../schema.types';
import { useCommonActions } from '../../store/commonStore';

export const useManagementPanel = () => {
  const [restaurants, setRestaurants] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [planCategories, setPlanCategories] = useState<any[]>([]);
  const [managementChoices, setManagementChoices] = useState<any>({});
  const [searchText, setSearchText] = useState('');
  const [filteredList, setFilteredList] = useState<RestaurantListApiSchema>([]);
  const [uploadDetails, setUploadDetails] =
    useState<RestaurantMenuUploadExcelApiSchema | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const { setSnackbarMessage } = useCommonActions();

  const { loggedInUser } = useUserStates();
  const isPrivileged = loggedInUser?.isPrivileged;

  useEffect(() => {
    (async () => {
      if (!isPrivileged) return;

      const [
        planCategoriesForManagementPanel,
        restaurantsForManagementPanel,
        managementChoices
      ] = await Promise.all([
        getPlanCategories(),
        getAllRestaurantsForManagement(),
        getManagementChoices()
      ]);
      setPlanCategories(
        planCategoriesForManagementPanel.map((plan: any) => ({
          label: plan.name,
          value: plan.id
        }))
      );
      setRestaurants(restaurantsForManagementPanel);
      setManagementChoices(managementChoices);
      setLoading(false);
    })();
  }, [isPrivileged]);

  const onSearch = useCallback(
    (searchQuery: string) => {
      setSearchText(searchQuery);
      if (searchQuery && searchQuery.length > 0) {
        const filteredList = restaurants.filter(r =>
          r.editValue.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredList(filteredList);
      } else {
        setFilteredList([]);
      }
    },
    [restaurants, searchText]
  );

  const onDrop = useCallback(
    async (files: File[]) => {
      if (!files[0]) return;

      try {
        setIsUploading(true);
        const selectedFile = files[0];
        const formData = new FormData();
        formData.append('excelFile', selectedFile);

        const response = await uploadRestaurantMenuExcel({
          file: files[0]
        });
        setUploadDetails(response);
      } catch (err) {
        setSnackbarMessage('Failed to upload file. Try again later!');
      } finally {
        setIsUploading(false);
      }
    },
    [setSnackbarMessage]
  );

  return {
    loading,
    planCategories,
    isPrivileged,
    managementChoices,
    searchText,
    uploadDetails,
    isUploading,
    setUploadDetails,
    onSearch,
    onDrop,
    restaurants: filteredList.length ? filteredList : restaurants
  };
};
