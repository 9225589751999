import {
  Ctas,
  ResHeader,
  RestaurantsCard,
  RowItem
} from '../ManagementPanel.styles';
import Select from '../../../components/Select/Select';
import TextInput from '../../../components/TextInput/TextInput';
import Button from '../../../components/Button/Button';
import { ResItemProps } from '../ManagementPanel.types';
import { useResItem } from './useResItem';

const ResMobileItem = ({
  restaurant,
  planCategories,
  resStates,
  optionsConfig: { options, onSelect }
}: ResItemProps) => {
  const {
    currentRes,
    isEditing,
    onEdit,
    onSave,
    setCurrentRes,
    setIsEditing,
    renderOptions
  } = useResItem({
    restaurant,
    optionsConfig: { options, onSelect }
  });

  const {
    resId,
    editValue: {
      name,
      cuisines,
      phoneNumbers,
      location: { areaName, fullAddress, gmapLink }
    },
    planCategory: { isReadOnly: isPlanCategoryReadOnly, id: planCategoryId },
    state: { isReadOnly: isReadOnlyState, state, remarks }
  } = restaurant;

  const selectedResState = state
    ? resStates.filter(item => item.value === state)[0]
    : null;
  const selectedResPlan = planCategoryId
    ? planCategories.filter(item => item.value === planCategoryId)[0]
    : null;

  return (
    <RestaurantsCard key={resId}>
      <ResHeader>
        <div className="resName">{name}</div>
        {renderOptions()}
      </ResHeader>
      <RowItem>
        <strong>Cuisines - </strong>
        <div>{cuisines.map((c: string) => c.trim()).join(', ')}</div>
      </RowItem>
      <RowItem>
        <strong>Phone Numbers - </strong>
        <div>{phoneNumbers.map((p: string) => p.trim()).join(', ')}</div>
      </RowItem>
      <RowItem>
        <strong>Area Name - </strong>
        <div>{areaName}</div>
      </RowItem>
      <RowItem>
        <strong>Full Address - </strong>
        <div>{fullAddress}</div>
      </RowItem>
      <RowItem>
        <strong>Map Link - </strong>
        <a target="_blank" rel="noreferrer" href={gmapLink}>
          {gmapLink}
        </a>
      </RowItem>

      {/* Editable keys start here */}
      <RowItem>
        <strong>State - </strong>
        <Select
          isDisabled={isReadOnlyState ? true : !isEditing}
          list={resStates}
          value={selectedResState}
          name=""
          label=""
          onChange={value => {
            const updatedRes = { ...currentRes };
            updatedRes.state.state = value.value;
            setCurrentRes(updatedRes);
          }}
        />
      </RowItem>
      <RowItem>
        <strong>Plan - </strong>
        <Select
          isDisabled={isPlanCategoryReadOnly ? true : !isEditing}
          list={planCategories}
          value={selectedResPlan}
          name=""
          label=""
          onChange={value => {
            const updatedRes = { ...currentRes };
            updatedRes.planCategory.id = value.value;
            setCurrentRes(updatedRes);
          }}
        />
      </RowItem>
      <RowItem>
        <strong>Remarks - </strong>
        <TextInput
          isDisabled={!isEditing}
          value={remarks}
          name=""
          label=""
          onChange={value => {
            const updatedRes = { ...currentRes };
            updatedRes.state.remarks = value;
            setCurrentRes(updatedRes);
          }}
        />
      </RowItem>
      {/* Editable keys end here */}

      {isEditing ? (
        <Ctas>
          <Button.Outlined onClick={() => setIsEditing(false)}>
            Cancel
          </Button.Outlined>
          <Button onClick={onSave}>Save</Button>
        </Ctas>
      ) : (
        <Button.Outlined onClick={onEdit}>Edit</Button.Outlined>
      )}
    </RestaurantsCard>
  );
};

export { ResMobileItem };
