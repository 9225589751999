import Loader from '../../components/Loader/Loader';
import {
  DetailsSubTitle,
  DetailsTitle,
  DetailsWrapper,
  HeaderContent,
  HeaderStatus,
  HeaderTitle,
  HeaderWrapper,
  PlanLabel,
  PlanContents,
  SubscriptionWrapper,
  PlanWrapper,
  PageHighlightOverlay,
  PlanSlider
} from './Subscription.styles';
import Button from '../../components/Button/Button';
import BackButton from '../../components/backButton/BackButton';
import { useSubscription } from './useSubscription';

const Subscription = () => {
  const {
    resSubs,
    loading,
    isSubscribed,
    onSubmit,
    title,
    subTitle,
    submitButtonText,
    isSuccessStateOnPage,
    pageHighlightText,
    selectedPlan,
    setSelectedPlan,
    isPreselected
  } = useSubscription();

  if (loading) return <Loader isFullScreen />;
  return (
    <SubscriptionWrapper>
      {pageHighlightText && (
        <PageHighlightOverlay>
          {pageHighlightText}
          <Button onClick={() => window.location.reload()}>Refresh</Button>
        </PageHighlightOverlay>
      )}
      <HeaderWrapper shouldFade={!!pageHighlightText}>
        <HeaderContent>
          <BackButton />
          <HeaderTitle>{resSubs.resName}</HeaderTitle>
        </HeaderContent>
        {resSubs.selectedPlan && (
          <HeaderStatus isSubscribed={!!isSubscribed}>
            {isSubscribed ? 'Subscribed' : 'Not subscribed'}
          </HeaderStatus>
        )}
      </HeaderWrapper>

      <DetailsWrapper shouldFade={!!pageHighlightText}>
        <DetailsTitle isSuccess={isSuccessStateOnPage}>{title}</DetailsTitle>
        <DetailsSubTitle>{subTitle}</DetailsSubTitle>

        {resSubs.availablePlans && (
          <PlanWrapper>
            <PlanLabel>Available Plans</PlanLabel>
            <PlanSlider>
              {resSubs.availablePlans.map(plan => (
                <PlanContents
                  isDisabled={isPreselected}
                  onClick={() => setSelectedPlan(plan)}
                  isSuccess={selectedPlan?.id === plan.id}
                >
                  {/* <div className="planDesc">{plan.description}</div> */}
                  <div className="planAmount">
                    <div className="actualAmount">
                      {'₹'}
                      {plan.amount}
                      {' per '}
                      {plan.period}
                    </div>
                    {plan.nonDiscountedAmount && (
                      <div className="discountInfo">
                        <div className="nonDiscountedAmount">
                          ₹{plan.nonDiscountedAmount}{' '}
                        </div>
                        <span>{plan.discountPercentage}% OFF</span>
                      </div>
                    )}
                  </div>
                  {plan.isRecommended && !isPreselected && (
                    <div className="tag">Recommended</div>
                  )}
                  {selectedPlan?.id === plan.id && isPreselected && (
                    <div className="tag">Current Plan</div>
                  )}
                </PlanContents>
              ))}
            </PlanSlider>
          </PlanWrapper>
        )}

        {submitButtonText && selectedPlan && (
          <Button.Outlined className="subscribeButton" onClick={onSubmit}>
            {submitButtonText}
          </Button.Outlined>
        )}
      </DetailsWrapper>
    </SubscriptionWrapper>
  );
};

export default Subscription;
