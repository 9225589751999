export const getPayload = (states: any) => {
  const {
    name,
    cuisines,
    dineInDetails,
    takeawayDetails,
    deliveryDetails,
    gmapLink,
    fullAddress,
    areaName,
    avgPriceForOne,
    phoneNumbers,
    isManagedByOwner,
    referredBy,
    restaurantTimings,
    resImage
  } = states;

  dineInDetails.timings = restaurantTimings;

  const requiredStringFields = [name, gmapLink, fullAddress, avgPriceForOne];
  const isAnyRequiredStringFieldEmpty = requiredStringFields.some(
    field => !field.value || field.error
  );
  const requiredSelectFields = [areaName, cuisines];
  const isAnyRequiredSelectFieldEmpty = requiredSelectFields.some(field => {
    if (!field) return true;
    if (Array.isArray(field)) {
      return field.length === 0;
    }
    return false;
  });
  if (isAnyRequiredStringFieldEmpty || isAnyRequiredSelectFieldEmpty) {
    return {
      error: 'Please fill all the required fields',
      payload: {}
    };
  }

  const anyPhoneNumbersHaveError =
    phoneNumbers.length === 0 || phoneNumbers.find((p: any) => p.error);
  if (anyPhoneNumbersHaveError) {
    return {
      error: 'Please enter valid phone numbers',
      payload: {}
    };
  }

  const isAnyFacilityEnabled =
    deliveryDetails.enabled || dineInDetails.enabled || takeawayDetails.enabled;
  if (!isAnyFacilityEnabled) {
    return {
      error:
        'Please select atleast one of the Facilities - Dine In, Delivery or TakeAway',
      payload: {}
    };
  }

  return {
    error: '',
    payload: {
      resLogo: resImage,
      cuisines: cuisines.map((c: any) => c.value.trim()),
      isManagedByOwner: isManagedByOwner,
      name: name.value.trim(),
      dineInDetails,
      deliveryDetails,
      takeawayDetails,
      location: {
        areaName: areaName.value.trim(),
        gmapLink: gmapLink.value.trim(),
        fullAddress: fullAddress.value.trim()
      },
      avgPrice: avgPriceForOne.value,
      phoneNumbers: phoneNumbers.map((p: any) => p.value),
      referredBy: referredBy.value
    }
  };
};
