import { create } from 'zustand';

type StoreType = {
  state: {
    // globals
    hideHeader: boolean;

    // others
    snackbarMessage: string;
    pageTitle: string;
    resChoices: null | {
      cuisines: string[];
      dishCategories: string[];
      dishNames: string[];
    };
    resImages: null | { path: string; fileName: string }[];
  };
  actions: {
    // globals
    setHideHeader: (hideHeader: boolean) => void;

    // others
    setSnackbarMessage: (snackbarMessage: string) => void;
    setResChoices: (resChoices: any) => void;
    setResImages: (resImages: any) => void;
    setCategoryList: (categoryList: any[]) => void;
    setDishNameList: (dishNameList: any[]) => void;
    setCuisines: (cuisines: any[]) => void;
    setPageTitle: (pageTitle: string) => void;
  };
};

const useCommonStore = create<StoreType>((set, get) => ({
  state: {
    // globals
    hideHeader: false,

    // others
    snackbarMessage: '',
    pageTitle: '',
    resChoices: null,
    resImages: null
  },
  actions: {
    // globals
    setHideHeader: hideHeader => set({ state: { ...get().state, hideHeader } }),

    // others
    setPageTitle: pageTitle => set({ state: { ...get().state, pageTitle } }),
    setSnackbarMessage: snackbarMessage =>
      set({ state: { ...get().state, snackbarMessage } }),
    setResChoices: resChoices => set({ state: { ...get().state, resChoices } }),
    setResImages: resImages => set({ state: { ...get().state, resImages } }),
    setCategoryList: (categoryList: any[]) => {
      set({
        state: {
          ...get().state,
          // @ts-expect-error TODO
          resChoices: {
            ...get().state.resChoices,
            dishCategories: categoryList
          }
        }
      });
    },
    setDishNameList: (dishNameList: any[]) =>
      set({
        state: {
          ...get().state,
          // @ts-expect-error TODO
          resChoices: {
            ...get().state.resChoices,
            dishNames: dishNameList
          }
        }
      }),
    setCuisines: (cuisines: any[]) =>
      set({
        state: {
          ...get().state,
          // @ts-expect-error TODO
          resChoices: {
            ...get().state.resChoices,
            cuisines
          }
        }
      })
  }
}));

export const useCommonStates = () => useCommonStore(state => state.state);
export const useCommonActions = () => useCommonStore(state => state.actions);

export default useCommonStore;
