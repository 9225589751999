import { COLORS } from '../../../common/theme';
import styled from 'styled-components';

export const ContactUsWrapper = styled.div<{ shouldShow: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  display: ${({ shouldShow }) => (shouldShow ? 'block' : 'none')};
  width: 100%;
  background-color: ${COLORS.white};
  padding: 20px;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 0 5px ${COLORS.primaryBlue};
  z-index: 10;
`;

export const Background = styled.div<{ shouldShow: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.black};
  opacity: 0.5;
  z-index: 5;
  display: ${({ shouldShow }) => (shouldShow ? 'block' : 'none')};
`;

export const PopupCloseIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;

  textarea {
    height: 200px;
    font-size: 18px;
  }
`;
