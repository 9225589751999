const SUCESS_TITLE = 'Yayyy!';
const SUCESS_SUBTITLE =
  'Your subscription is ACTIVE. The customer are able to checkout your restaurant on our customer app.';
const FAILURE_TITLE = 'Payment Failed!';
const FAILURE_SUBTITLE =
  'Please complete the payment to activate your subscription. The restaurant is currently INACTIVE, it will be listed on our customer app once the payment is successful.';
const PENDING_TITLE = 'Payment Confirmation Pending!';
const PENDING_SUBTITLE =
  'Please complete the payment to activate your subscription. The restaurant is currently INACTIVE, it will be listed on our customer app once the payment is successful.';

export const getPageTexts = ({
  availablePlans,
  subscription,
  paymentStatus
}: {
  availablePlans?: any[];
  subscription?: any;
  paymentStatus?: any;
}) => {
  let title = '';
  let subTitle = '';
  let submitButtonText = '';
  const status = subscription?.status;
  const isSubscribed = subscription?.isSubscribed;

  const planExists = availablePlans && availablePlans?.length > 0;
  if (!planExists) {
    title = 'No plans found!';
    subTitle = 'Please connect with our support team.';
  } else if (paymentStatus?.status === 'IN_PROGRESS') {
    title = PENDING_TITLE;
    subTitle = PENDING_SUBTITLE;
  } else if (isSubscribed && paymentStatus?.status === 'SUCCESS') {
    title = SUCESS_TITLE;
    subTitle = SUCESS_SUBTITLE;
  } else if (
    ['PENDING', 'HALTED'].includes(status) ||
    paymentStatus?.status === 'FAILED'
  ) {
    title = FAILURE_TITLE;
    subTitle = FAILURE_SUBTITLE;
    submitButtonText = 'Proceed to Pay';
  } else if (!isSubscribed) {
    title = 'Not yet subscribed!';
    subTitle = 'Subscribe to avail complete benefits.';
    submitButtonText = 'Subscribe';
  }

  return {
    title,
    subTitle,
    submitButtonText,
    isSuccessStateOnPage: title === SUCESS_TITLE
  };
};
