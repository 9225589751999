import { useMemo, useState } from 'react';
import Button from '../../components/Button/Button';
import Overlay from '../../components/overlay/Overlay';
import { useCommonStates } from '../../store/commonStore';
import {
  ActionButtons,
  ImageSelectorContainer,
  ImagesPreview,
  ImagesPreviewContainer,
  SelectedImageTag
} from './AddRestaurant.styles';
import {
  FormFieldProps,
  WithFormField
} from '../../hoc/withFormField/WithFormField';
import { getImageUrl } from '../../common/utils';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { COLORS } from '../../common/theme';

const ImageSelector = ({ value, onChange }: FormFieldProps) => {
  const { resImages } = useCommonStates();
  const [popup, setPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(value);

  const selectedImagePath = useMemo(() => {
    return resImages?.find(img => img.fileName === selectedImage)?.path || '';
  }, [resImages, selectedImage]);

  return (
    <ImageSelectorContainer>
      <div className="imageSelectorBtn" onClick={() => setPopup(true)}>
        {value ? 'Change restaurant image' : 'Select a restaurant image'}
      </div>
      {value && (
        <SelectedImageTag
          src={getImageUrl(selectedImagePath)}
          alt="Restaurant Image"
        />
      )}

      <Overlay
        isFullScreen
        isVisible={popup}
        placement={Overlay.PLACEMENTS.Bottom}
        onClose={() => setPopup(false)}
      >
        <ImagesPreviewContainer>
          <ImagesPreview>
            {resImages?.map(img => {
              const isSelected = img.fileName === selectedImage;
              return (
                <div className="preview-img">
                  <img
                    className={isSelected ? 'selected' : ''}
                    src={getImageUrl(img.path)}
                    onClick={() => setSelectedImage(img.fileName)}
                  />
                  {isSelected && (
                    <IoCheckmarkCircleSharp
                      color={COLORS.orange}
                      className="selected-icon"
                    />
                  )}
                </div>
              );
            })}
          </ImagesPreview>
          <ActionButtons>
            <Button.Outlined onClick={() => setPopup(false)}>
              Cancel
            </Button.Outlined>
            <Button
              onClick={() => {
                setPopup(false);
                onChange(selectedImage);
              }}
            >
              Select
            </Button>
          </ActionButtons>
        </ImagesPreviewContainer>
      </Overlay>
    </ImageSelectorContainer>
  );
};

export default WithFormField(ImageSelector);
