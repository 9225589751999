import { useEffect, useState } from 'react';
import { AddCta, List, RestaurantsWrapper } from './Restaurants.styles';
import Loader from '../../components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../common/constants';
import { getRestaurantList } from '../../actions/actions';
import { useUserStates } from '../../store/userStore';
import { useCommonActions } from '../../store/commonStore';
import Button from '../../components/Button/Button';
import ListItem from './ListItem';
import { RestaurantListApiSchema } from '../../schema.types';
import TextInput from '../../components/TextInput/TextInput';

const Restaurants = () => {
  const [loading, setLoading] = useState(true);
  const [restaurants, setRestaurants] = useState<RestaurantListApiSchema>([]);
  const [searchText, setSearchText] = useState('');
  const [filteredList, setFilteredList] = useState<RestaurantListApiSchema>([]);

  const loggedInUser = useUserStates().loggedInUser;

  const { setSnackbarMessage } = useCommonActions();

  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      try {
        const list = await getRestaurantList();
        setRestaurants(list);
        setLoading(false);
      } catch (err) {
        setSnackbarMessage('Error fetching restaurants');
      }
    })();
  }, [loggedInUser, setSnackbarMessage]);

  const renderList = () => {
    const listToRender = filteredList.length ? filteredList : restaurants;

    const items = listToRender.map((r, index) => {
      return <ListItem key={index} resObject={r} />;
    });

    return <List>{items}</List>;
  };

  const addRestaurant = () => {
    navigate(APP_ROUTES.addRestaurant);
  };

  const onSearch = (searchQuery: string) => {
    setSearchText(searchQuery);
    if (searchQuery && searchQuery.length > 0) {
      const filteredList = restaurants.filter(r =>
        r.resName.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredList(filteredList);
    } else {
      setFilteredList([]);
    }
  };

  return (
    <RestaurantsWrapper>
      {loading ? (
        <Loader isFullScreen />
      ) : (
        <>
          {restaurants?.length && restaurants?.length > 10 ? (
            <TextInput
              name=""
              placeholder="Search a Restaurant"
              value={searchText}
              onChange={onSearch}
              theme={TextInput.THEMES.SEARCH}
            />
          ) : (
            <div style={{ padding: 5 }} />
          )}
          {restaurants?.length ? renderList() : <></>}
          <AddCta>
            <Button.Outlined onClick={addRestaurant}>
              Add Restaurant
            </Button.Outlined>
          </AddCta>
        </>
      )}
    </RestaurantsWrapper>
  );
};

export default Restaurants;
