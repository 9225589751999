import React, { useState } from 'react';
import { CheckboxWrapper } from './Checkbox.styles';

const Checkbox = ({
  label,
  name,
  value: propValue,
  onChange,
  isRequired
}: {
  name: string;
  label?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  isRequired?: boolean;
}) => {
  const [value, setValue] = useState(!!propValue);

  const onCheckChange = (checked: boolean) => {
    setValue(checked);
    onChange?.(checked);
  };

  return (
    <CheckboxWrapper>
      <input
        required={isRequired}
        name={name}
        type="checkbox"
        checked={!!value}
        onChange={(event: any) => onCheckChange(!!event?.target.checked)}
      />
      <label htmlFor={name} onClick={() => onCheckChange(!value)}>
        {label}
      </label>
    </CheckboxWrapper>
  );
};

export default Checkbox;
