export enum Placement {
  Right = 'right',
  Bottom = 'bottom'
}

export type OverlayProps = React.PropsWithChildren<{
  isVisible: boolean;
  placement: Placement;
  onClose: () => void;
  shouldShowClose?: boolean;
  isFullScreen?: boolean;
}>;
