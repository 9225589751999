import { ResListProps } from '../ManagementPanel.types';
import { ResMobileItem } from './ResMobileItem';
import { ResDeskTopItem } from './ResDesktopItem';
import { RestaurantsList, Row, RowItem } from '../ManagementPanel.styles';
import { useState } from 'react';
import OptionsOverlays from './optionsOverlays/OptionsOverlays';

const RES_ITEM_OPTIONS = [
  {
    label: 'Update ownership',
    value: 'UPDATE_OWNERSHIP'
  }
];

const ResList = (props: ResListProps) => {
  const { restaurants, planCategories, resStates } = props;
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedRes, setSelectedRes] = useState(null);

  const onSelect = (value: string, restaurant: any) => {
    setSelectedOption(value);
    setSelectedRes(restaurant);
  };

  return (
    <>
      <RestaurantsList>
        <Row isHeader>
          <RowItem>State</RowItem>
          <RowItem>Name</RowItem>
          <RowItem>Cuisines</RowItem>
          <RowItem>Phone Numbers</RowItem>
          <RowItem>Area Name</RowItem>
          <RowItem>Full address</RowItem>
          <RowItem>Google Maps Link</RowItem>
          <RowItem>Res State</RowItem>
          <RowItem>Res Plan</RowItem>
          <RowItem>Remarks</RowItem>
          <RowItem>Action</RowItem>
        </Row>
        {restaurants.map((restaurant: any) => (
          <>
            <ResMobileItem
              restaurant={restaurant}
              planCategories={planCategories}
              resStates={resStates}
              optionsConfig={{
                onSelect,
                options: RES_ITEM_OPTIONS
              }}
            />
            <ResDeskTopItem
              restaurant={restaurant}
              planCategories={planCategories}
              resStates={resStates}
              optionsConfig={{
                onSelect,
                options: RES_ITEM_OPTIONS
              }}
            />
          </>
        ))}
      </RestaurantsList>
      <OptionsOverlays
        selectedOption={selectedOption}
        selectedRes={selectedRes}
        setSelectedOption={setSelectedOption}
        setSelectedRes={setSelectedRes}
      />
    </>
  );
};

export default ResList;
