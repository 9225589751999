import { APP_ROUTES } from '../../common/constants';

export const PAGE_TITLES = {
  [APP_ROUTES.restaurants]: 'Your Restaurants',
  [`${APP_ROUTES.restaurants}/`]: 'Your Restaurants',

  [APP_ROUTES.login]: 'Login',
  [`${APP_ROUTES.login}/`]: 'Login',

  [APP_ROUTES.menu]: 'Menu',
  [`${APP_ROUTES.menu}/`]: 'Menu',

  [APP_ROUTES.addRestaurant]: 'Add a new restaurant',
  [APP_ROUTES.managementPanel]: 'Management Panel',
  [APP_ROUTES.userQueries]: 'Your queries'
};
