import { Ctas, Row, RowItem } from '../ManagementPanel.styles';
import Select from '../../../components/Select/Select';
import TextInput from '../../../components/TextInput/TextInput';
import Button from '../../../components/Button/Button';
import { ResItemProps } from '../ManagementPanel.types';
import { useResItem } from './useResItem';

const ResDeskTopItem = ({
  restaurant,
  planCategories,
  resStates,
  optionsConfig: { options, onSelect }
}: ResItemProps) => {
  const {
    currentRes,
    isEditing,
    onEdit,
    onSave,
    setCurrentRes,
    setIsEditing,
    renderOptions
  } = useResItem({
    restaurant,
    optionsConfig: { options, onSelect }
  });

  const {
    resId,
    editValue: {
      name,
      cuisines,
      phoneNumbers,
      location: { areaName, fullAddress, gmapLink }
    },
    planCategory: { isReadOnly: isPlanCategoryReadOnly, id: planCategoryId },
    state: { isReadOnly: isReadOnlyState, state, remarks }
  } = restaurant;

  const selectedResState = state
    ? resStates.filter(item => item.value === state)[0]
    : null;
  const selectedResPlan = planCategoryId
    ? planCategories.filter(item => item.value === planCategoryId)[0]
    : null;

  return (
    <Row key={resId}>
      <RowItem>{state}</RowItem>
      <RowItem>{name}</RowItem>
      <RowItem>{cuisines.map((c: string) => c.trim()).join(', ')}</RowItem>
      <RowItem>{phoneNumbers.map((p: string) => p.trim()).join(', ')}</RowItem>
      <RowItem>{areaName}</RowItem>
      <RowItem>{fullAddress}</RowItem>
      <RowItem>
        <a target="_blank" rel="noreferrer" href={gmapLink}>
          {gmapLink}
        </a>
      </RowItem>
      {/* Editable keys start here */}
      <RowItem>
        <Select
          isDisabled={isReadOnlyState ? true : !isEditing}
          list={resStates}
          value={selectedResState}
          name=""
          label=""
          onChange={value => {
            const updatedRes = { ...currentRes };
            updatedRes.state.state = value.value;
            setCurrentRes(updatedRes);
          }}
        />
      </RowItem>
      <RowItem>
        <Select
          isDisabled={isPlanCategoryReadOnly ? true : !isEditing}
          list={planCategories}
          value={selectedResPlan}
          name=""
          label=""
          onChange={value => {
            const updatedRes = { ...currentRes };
            updatedRes.planCategory.id = value.value;
            setCurrentRes(updatedRes);
          }}
        />
      </RowItem>
      <RowItem>
        <TextInput
          isDisabled={!isEditing}
          value={remarks}
          name=""
          label=""
          onChange={value => {
            const updatedRes = { ...currentRes };
            updatedRes.state.remarks = value;
            setCurrentRes(updatedRes);
          }}
        />
      </RowItem>
      {/* Editable keys end here */}

      <RowItem>
        {isEditing ? (
          <Ctas>
            <Button.Outlined onClick={() => setIsEditing(false)}>
              Cancel
            </Button.Outlined>
            <Button onClick={onSave}>Save</Button>
          </Ctas>
        ) : (
          <Button.Outlined onClick={onEdit}>Edit</Button.Outlined>
        )}
        {renderOptions()}
      </RowItem>
    </Row>
  );
};

export { ResDeskTopItem };
