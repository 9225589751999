import styled from 'styled-components';

export const DropzoneWrapper = styled.div`
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
