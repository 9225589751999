import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RestaurantDetailsApiSchema } from '../../schema.types';
import { useNoGlobalHeader } from '../../global/useNoGlobalHeader';
import { getRestaurantDetails } from '../../actions/actions';
import { APP_ROUTES } from '../../common/constants';

export const useRestaurantDetails = () => {
  const navigate = useNavigate();
  const { resId } = useParams();

  const [resDetails, setResDetails] = useState<RestaurantDetailsApiSchema>(
    {} as RestaurantDetailsApiSchema
  );
  const [loading, setLoading] = useState(true);

  const popupRef = useRef<HTMLDivElement>(null);

  useNoGlobalHeader();

  useEffect(() => {
    (async () => {
      if (!resId) {
        navigate(APP_ROUTES.restaurants, {
          replace: true
        });
      } else {
        const resDetailsData = await getRestaurantDetails(resId);
        setResDetails(resDetailsData);

        setLoading(false);
      }
    })();
  }, [navigate, resId]);

  const getApprovalText = (state: string) => {
    switch (state) {
      case 'APPROVED':
        return 'Approved';
      case 'IN_REVIEW':
        return 'In Review';
      case 'REJECTED':
        return 'Rejected';
      default:
        return 'In Review';
    }
  };

  const editRestaurant = () => {
    navigate(APP_ROUTES.addRestaurant, {
      state: { restaurant: resDetails }
    });
  };

  const manageSubscription = () => {
    navigate(APP_ROUTES.subscription, {
      state: { resId: resDetails.resId }
    });
  };

  const openMenu = () => {
    navigate(APP_ROUTES.menu, { state: { resId: resDetails.resId } });
  };

  return {
    resDetails,
    loading,
    popupRef,
    getApprovalText,
    editRestaurant,
    manageSubscription,
    openMenu
  };
};
