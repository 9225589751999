import { useEffect, useState } from 'react';

export const useOutsideClick = (ref: any) => {
  const [isOpen, togglePopup] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        togglePopup(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, ref]);

  return {
    isOpen,
    togglePopup
  };
};
