import { css, styled } from 'styled-components';
import { COLORS } from '../../../common/theme';
import { MOBILE_BREAKPOINT } from '../../../common/constants';

export const UserProfileMenuWrapper = styled.div`
  display: flex;
  align-items: center;

  .menuButton {
    cursor: pointer;

    @media (min-width: ${MOBILE_BREAKPOINT}px) {
      display: none;
    }
  }
`;

export const UserProfileDetailsPopup = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '100%')};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.white};
  box-shadow: -1px 1px 1px 1px gray;
  transition: left 0.3s ease;
  overflow: hidden;
  ${props =>
    props.isOpen
      ? css`
          width: 100vw;
        `
      : css`
          width: 0;
        `}
`;

export const PopupCloseIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
  padding: 10px;
`;

export const Footer = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  padding: 10px;

  .logOutButton {
    width: 90%;
  }
`;

const APP_FOOTER_HEIGHT = 50;
export const FooterContents = styled.div`
  width: 100%;
  height: ${APP_FOOTER_HEIGHT}px;
  margin-top: auto;
  overflow: hidden;
  color: ${COLORS.black};
  padding: 10px 20px;
  text-align: center;
  background-color: ${COLORS.white};

  strong {
    border-bottom: 1px solid ${COLORS.black};
  }
`;

export const UserDetails = styled.div`
  width: 100%;
  color: ${COLORS.black};
  text-align: center;
  padding: 10px;
  padding-bottom: 30px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    width: auto;
    font-size: 16px;

    .avatar {
      display: none;
    }
  }
`;

export const PanelLinks = styled.div`
  flex: 1;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;

  .link {
    position: relative;
    color: ${COLORS.lightBlue};
    cursor: pointer;
    font-size: 18px;

    &::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      height: 1px;
      background-color: ${COLORS.lightBlue};
      transition: width 0.3s;
    }
  }

  // media query for desktop
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: row;
    gap: 20px;

    .link {
      font-size: 16px;
    }
  }
`;

// Desktop styles
export const UserDetailsDesktop = styled.div`
  display: none;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    gap: 10px;

    .avatar {
      display: none;
    }
  }
`;
