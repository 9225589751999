import { styled } from 'styled-components';
import { COLORS } from '../../common/theme';

export const AppHeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${COLORS.white};
  padding: 20px 10px;
  box-shadow: 0 1px 3px ${COLORS.primaryBlue};
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AppHeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;
