import styled from 'styled-components';
import { COLORS } from '../../common/theme';

export const SubscriptionWrapper = styled.div``;

export const PageHighlightOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px;
  font-size: 30px;
  font-weight: 600;
  z-index: 1;
  gap: 10px;
`;

export const HeaderWrapper = styled.div<{ shouldFade: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  height: 50px;
  box-shadow: 0 1px 3px ${COLORS.primaryBlue};
  ${({ shouldFade }) => (shouldFade ? 'opacity: 0.3' : '')}
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  max-width: 230px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const HeaderStatus = styled.div<{ isSubscribed: boolean }>`
  flex: 1;
  white-space: nowrap;
  text-align: center;
  color: ${({ isSubscribed }) => (isSubscribed ? COLORS.green : COLORS.red)};
  font-weight: 600;
  font-size: 12px;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid
    ${({ isSubscribed }) => (isSubscribed ? COLORS.green : COLORS.red)};
  text-transform: capitalize;
  box-shadow: 0 0 2px
    ${({ isSubscribed }) => (isSubscribed ? COLORS.green : COLORS.red)};
`;

export const DetailsWrapper = styled.div<{ shouldFade: boolean }>`
  ${({ shouldFade }) => (shouldFade ? 'opacity: 0.3' : '')}
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  flex: 1;

  .subscribeButton {
    margin-top: 50px;
    width: 100%;
  }
`;

export const DetailsTitle = styled.div<{ isSuccess?: boolean }>`
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
  color: ${({ isSuccess }) => (isSuccess ? COLORS.green : COLORS.red)};
`;

export const DetailsSubTitle = styled.div`
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
`;

export const PlanWrapper = styled.div`
  margin-top: 50px;
  position: relative;
`;

export const PlanLabel = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
`;

export const PlanSlider = styled.div`
  width: 100%;
`;

export const PlanContents = styled.div<{
  isSuccess?: boolean;
  isDisabled?: boolean;
}>`
  border: 2px solid
    ${({ isSuccess }) => (isSuccess ? COLORS.primaryBlue : COLORS.gray3)};
  background-color: ${({ isSuccess }) =>
    isSuccess ? `${COLORS.primaryBlue}30` : ''};
  color: ${({ isSuccess }) => (isSuccess ? COLORS.primaryBlue : COLORS.gray3)};
  border-radius: 8px;
  margin-bottom: 10px;
  position: relative;
  min-height: 80px;
  display: flex;
  align-items: center;

  ${({ isDisabled }) => (isDisabled ? 'pointer-events: none;' : '')}

  .tag {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 3px;
    border-radius: 5px;
    border: 1px solid ${COLORS.green};
    background-color: ${COLORS.white};
    color: ${COLORS.green};
  }

  .planAmount {
    padding: 15px;

    ${({ isDisabled }) => (isDisabled ? 'opacity: 0.5;' : '')}

    .actualAmount {
      font-size: 18px;
      font-weight: 600;
    }

    .discountInfo {
      display: flex;
      align-items: center;

      .nonDiscountedAmount {
        display: inline-block;
        margin-top: 5px;
        margin-right: 5px;
        font-size: 14px;
        text-decoration: line-through;
        color: ${({ isSuccess }) => (isSuccess ? COLORS.black : COLORS.gray3)};
      }

      span {
        color: ${({ isSuccess }) => (isSuccess ? COLORS.green : COLORS.gray3)};
        font-weight: 600;
        font-style: italic;
        margin-top: 5px;
        margin-left: 5px;
        font-size: 16px;
        text-decoration: none !important;
      }
    }
  }

  .planDesc {
    width: 100%;
    flex: 0.7;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    background-color: ${COLORS.white};
    color: ${({ isSuccess }) =>
      isSuccess ? COLORS.primaryBlue : COLORS.gray3};
  }
`;
