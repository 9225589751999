import Button from '../../components/Button/Button';
import { removeItemInLocalStorageWithAsliMenuPrefix } from '../../common/utils';
import { APP_ROUTES } from '../../common/constants';
import {
  RestaurantsWrapper,
  UnauthorizedWrapper
} from './ManagementPanel.styles';
import { useManagementPanel } from './useManagementPanel';
import Loader from '../../components/Loader/Loader';
import TextInput from '../../components/TextInput/TextInput';
import ResList from './resItem/ResList';
import { FileDropzone } from '../../components/FileDropzone/FileDropzone';
import Overlay from '../../components/overlay/Overlay';
import { UploadDetailsOverlayContent } from './UploadDetailsOverlayContent';

const ManagementPanel = () => {
  const {
    loading,
    restaurants,
    planCategories,
    isPrivileged,
    managementChoices,
    searchText,
    onSearch,
    onDrop,
    uploadDetails,
    setUploadDetails,
    isUploading
  } = useManagementPanel();
  const { resStates = [] } = managementChoices;

  if (!isPrivileged) {
    return (
      <UnauthorizedWrapper>
        Not authorized
        <Button
          onClick={() => {
            removeItemInLocalStorageWithAsliMenuPrefix();

            window.location.replace(
              `${window.location.origin}${APP_ROUTES.login}`
            );
          }}
        >
          Log out
        </Button>
      </UnauthorizedWrapper>
    );
  }

  if (loading) {
    return <Loader isFullScreen />;
  }

  return (
    <>
      <RestaurantsWrapper>
        <TextInput
          name=""
          placeholder="Search a Restaurant"
          value={searchText}
          onChange={onSearch}
          theme={TextInput.THEMES.SEARCH}
        />
        <FileDropzone onDrop={onDrop} isUploading={isUploading} />
        <ResList
          restaurants={restaurants}
          planCategories={planCategories}
          resStates={resStates}
        />
      </RestaurantsWrapper>
      <Overlay
        shouldShowClose
        isVisible={!!uploadDetails}
        placement={Overlay.PLACEMENTS.Bottom}
        onClose={() => setUploadDetails(null)}
      >
        <UploadDetailsOverlayContent uploadDetails={uploadDetails} />
      </Overlay>
    </>
  );
};

export default ManagementPanel;
