export const MOBILE_BREAKPOINT = 780;

export const LOCAL_STORAGE_KEY_REFIX = 'ASLI_MENU:';

export const APP_AUTH_TOKEN_KEY = `${LOCAL_STORAGE_KEY_REFIX}authToken`;

export const MAX_RESTAURANT_PHONE_COUNT = 4;
export const MAX_RESTAURANT_PHOTO_COUNT = 2;
export const MAX_RESTAURANT_TIMINGS_COUNT = 3;

export const PHONE_PREFIX = '+91';

export const API_URL = process.env.REACT_APP_API_HOST;

export const APP_ROUTES = {
  // root paths
  home: '/',
  login: '/login',
  managementPanel: '/management-panel',

  // user paths
  userQueries: '/user/queries',

  // restaurant paths
  restaurants: '/restaurants',
  addRestaurant: '/restaurants/add',
  menu: '/restaurants/menu',
  subscription: '/restaurants/subscription'
};

export const RES_STATES = [
  { value: 'IN_REVIEW', label: 'In Review' },
  { value: 'APPROVED', label: 'Approved' }
];

export const REDIRECT_LINKS = {
  website: 'https://aslimenu.com',
  privacy: 'https://aslimenu.com/privacy'
};
