import Loader from '../../components/Loader/Loader';
import QueryItem from './QueryItem';
import { useUserQueries } from './useUserQueries';

const UserQueries = () => {
  const { loading, queries } = useUserQueries();

  if (loading) {
    return <Loader isFullScreen />;
  }

  if (!queries.length) {
    return (
      <div
        style={{
          textAlign: 'center',
          marginTop: '20px',
          fontSize: '18px'
        }}
      >
        No queries found
      </div>
    );
  }

  return (
    <div>
      {queries.map(query => (
        <QueryItem key={query.id} query={query} />
      ))}
    </div>
  );
};

export default UserQueries;
