import { styled } from 'styled-components';
import { COLORS } from '../../common/theme';
import { BUTTON_TYPES } from './Button.types';

export const ButtonContainer = styled.div<{
  disabled: boolean;
  isLoading?: boolean;
}>`
  ${({ disabled }) =>
    disabled
      ? `
  pointer-events: none;
  opacity: 0.2;`
      : ''}

  ${({ isLoading }) =>
    isLoading
      ? `
  pointer-events: none;`
      : ''}
`;

export const StyledButton = styled.button<{ btnType?: BUTTON_TYPES }>`
  width: 100%;
  height: 40px;
  margin: 10px 0;
  cursor: pointer;

  border: 1px solid ${COLORS.primaryBlue};
  ${props =>
    props.btnType === BUTTON_TYPES.FILLED
      ? `  color: ${COLORS.white};
  background-color: ${COLORS.primaryBlue};`
      : `  color: ${COLORS.primaryBlue};
  background-color: ${COLORS.white};`}

  text-transform: uppercase;
  font-weight: bold;
  opacity: 1;
  border-radius: 5px;
  box-shadow: 0 0 2px ${COLORS.primaryBlue};
`;
