import { useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import { Outlet } from 'react-router-dom';
import { useCommonActions } from '../../store/commonStore';
import {
  getRestaurantChoices,
  getRestaurantImages
} from '../../actions/actions';

const RestaurantsRoot = () => {
  const [loading, setLoading] = useState(true);

  const { setSnackbarMessage, setResChoices, setResImages } =
    useCommonActions();

  useEffect(() => {
    (async function () {
      try {
        const [resChoices, resImages] = await Promise.all([
          getRestaurantChoices(),
          getRestaurantImages()
        ]);
        setResChoices(resChoices);
        setResImages(resImages);
      } catch (err) {
        setSnackbarMessage('Error fetching restaurants choices');
      } finally {
        setLoading(false);
      }
    })();
  }, [setResChoices, setResImages, setSnackbarMessage]);

  if (loading) return <Loader isFullScreen />;

  return <Outlet />;
};

export default RestaurantsRoot;
