import { API_URL } from '../common/constants';

export const API_ENDPOINTS = {
  user: `${API_URL}/users/`,
  restaurant: `${API_URL}/partner/restaurants/`,
  restaurantSubscription: `${API_URL}/partner/restaurants/subscription/`,
  restaurantChoices: `${API_URL}/partner/restaurants/choices/`,
  restaurantLogoPaths: `${API_URL}/partner/restaurants/images/logo/paths/`,
  sendOtp: `${API_URL}/users/send-otp/`,
  verifyOtp: `${API_URL}/users/verify-otp/`,

  // Management panel APIs
  restaurantsForManagementPanel: `${API_URL}/partner/manage/restaurants/`,
  planCategories: `${API_URL}/partner/manage/restaurants/plans/categories/`,
  managementChoices: `${API_URL}/partner/manage/restaurants/choices/`,
  uploadRestaurantMenuExcel: `${API_URL}/partner/manage/restaurants/upload-menu/`,

  // v2 endpoints
  restaurantV2: `${API_URL}/partner/v2/restaurants/`,

  // User queries endpoints
  userQueries: `${API_URL}/users/queries/`
};
