import { StyledButton, ButtonContainer } from './Button.styles';
import { BUTTON_TYPES, ButtonProps } from './Button.types';
import Loader from '../Loader/Loader';
import { useState } from 'react';

const Button = ({
  children,
  isDisabled,
  className,
  onClick,
  isLoading,
  role,
  btnType = BUTTON_TYPES.FILLED
}: ButtonProps) => {
  const [loading, setLoading] = useState(false);

  const onButtonClick = async () => {
    if (onClick) {
      setLoading(true);
      await onClick();
      setLoading(false);
    }
  };

  return (
    <ButtonContainer
      className={className}
      disabled={!!isDisabled}
      isLoading={isLoading || loading}
      onClick={onButtonClick}
    >
      <StyledButton type={role} btnType={btnType}>
        {isLoading || loading ? <Loader isSmall /> : children}
      </StyledButton>
    </ButtonContainer>
  );
};

Button.Outlined = (props: ButtonProps) => {
  return <Button {...props} btnType={BUTTON_TYPES.OUTLINED} />;
};

export default Button;
