import styled from 'styled-components';
import { RestaurantMenuUploadExcelApiSchema } from '../../schema.types';

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 50vh;
  padding: 20px;
`;
const DetailsItem = styled.div``;

const UploadDetailsOverlayContent = ({
  uploadDetails
}: {
  uploadDetails: RestaurantMenuUploadExcelApiSchema | null;
}) => {
  return (
    uploadDetails && (
      <DetailsWrapper>
        <h3>Upload Details:</h3>
        {uploadDetails.UPDATED?.length > 0 && (
          <DetailsItem>
            <div>
              <strong>Successfully updated:</strong>{' '}
            </div>
            <div>{uploadDetails.UPDATED.join(', ')}</div>
          </DetailsItem>
        )}
        {uploadDetails.NOT_FOUND_IN_DATABASE?.length > 0 && (
          <DetailsItem>
            <div>
              <strong>Not Found:</strong>
            </div>
            <div>{uploadDetails.NOT_FOUND_IN_DATABASE.join(', ')}</div>
          </DetailsItem>
        )}
        {uploadDetails.MULTIPLE_WITH_SAME_NAME?.length > 0 && (
          <DetailsItem>
            <div>
              Multiple Restaurants with <strong>Same Name</strong>:
            </div>
            <div>{uploadDetails.MULTIPLE_WITH_SAME_NAME.join(', ')}</div>
          </DetailsItem>
        )}
        {uploadDetails.NO_VALID_MENU_IN_SHEET?.length > 0 && (
          <DetailsItem>
            <div>
              Restaurants with <strong>Invalid Menu in Sheet</strong>:
            </div>
            <div>{uploadDetails.NO_VALID_MENU_IN_SHEET.join(', ')}</div>
          </DetailsItem>
        )}
        {uploadDetails.MENU_ALREADY_PRESENT?.length > 0 && (
          <DetailsItem>
            <div>
              <strong>Restaurants already present:</strong>
            </div>
            <div>{uploadDetails.MENU_ALREADY_PRESENT.join(', ')}</div>
          </DetailsItem>
        )}
      </DetailsWrapper>
    )
  );
};

export { UploadDetailsOverlayContent };
