import { Backdrop, OverlayContainer, OverlayContent } from './Overlay.styles';
import { OverlayProps, Placement } from './Overlay.types';
import { CgClose } from 'react-icons/cg';

const Overlay = (props: OverlayProps) => {
  const {
    children,
    isFullScreen,
    placement,
    onClose,
    isVisible,
    shouldShowClose
  } = props;

  return (
    <>
      {isVisible && <Backdrop onClick={onClose} />}
      <OverlayContainer
        isOpen={isVisible}
        isFullScreen={!!isFullScreen}
        placement={placement}
      >
        <OverlayContent>
          {children}
          {shouldShowClose && <CgClose className="close" onClick={onClose} />}
        </OverlayContent>
      </OverlayContainer>
    </>
  );
};

Overlay.PLACEMENTS = Placement;

export default Overlay;
