import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AppHeader from '../../components/AppHeader/AppHeader';
import { useUserActions, useUserStates } from '../../store/userStore';
import Loader from '../../components/Loader/Loader';
import {
  AppContentWrapper,
  AppHeaderWrapper,
  AppRootWrapper
} from './AppRoot.styles';
import {
  getItemFromLocalStorage,
  removeItemInLocalStorage
} from '../../common/utils';
import { APP_AUTH_TOKEN_KEY, APP_ROUTES } from '../../common/constants';
import Snackbar from '../../components/Snackbar/Snackbar';
import { useCommonActions, useCommonStates } from '../../store/commonStore';
import { getUser } from '../../actions/actions';

const AppRoot = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const loggedInUser = useUserStates().loggedInUser;
  const setLoggedInUser = useUserActions().setLoggedInUser;

  const { setSnackbarMessage } = useCommonActions();
  const { hideHeader } = useCommonStates();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    /**
     * Check for auth token -
     *  - If token is present, set the user in the store -> newUser -> signUp page else Res list page
     *  - If token is not present, navigate to login page
     */
    (async function () {
      setLoading(true);
      const authToken = getItemFromLocalStorage(APP_AUTH_TOKEN_KEY, undefined);
      if (authToken) {
        try {
          const user = await getUser();

          if (user.isNewUser) {
            removeItemInLocalStorage(APP_AUTH_TOKEN_KEY);
            navigate(APP_ROUTES.login, { replace: true });
          } else {
            setLoggedInUser(user);
          }
        } catch (error) {
          setSnackbarMessage('Error fetching user details');
        }
      } else {
        navigate(APP_ROUTES.login, { replace: true });
      }
      setLoading(false);
    })();
    // To be run only once when we land on the APP
  }, []);

  useEffect(() => {
    if (
      loggedInUser &&
      [APP_ROUTES.home, APP_ROUTES.login].includes(location.pathname)
    ) {
      navigate(APP_ROUTES.restaurants, { replace: true });
    }
    // To be run when we land on a new page
  }, [location.pathname, loggedInUser, navigate]);

  return (
    <AppRootWrapper>
      {loading ? (
        <Loader isFullScreen />
      ) : (
        <>
          {hideHeader ? (
            <></>
          ) : (
            <AppHeaderWrapper>
              <AppHeader />
            </AppHeaderWrapper>
          )}
          <AppContentWrapper>
            <Outlet />
          </AppContentWrapper>

          {/* Globally available components */}
          <Snackbar />
        </>
      )}
    </AppRootWrapper>
  );
};

export default AppRoot;
