import { AppHeaderWrapper, AppHeaderTitle } from './AppHeader.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGE_TITLES } from './Appheader.constants';
import UserProfileMenu from './UserProfileMenu/UserProfileMenu';
import { APP_ROUTES } from '../../common/constants';
import BackButton from '../backButton/BackButton';
import { useUserStates } from '../../store/userStore';
import { useCommonStates } from '../../store/commonStore';

const AppHeader = () => {
  const { loggedInUser } = useUserStates();
  const { pageTitle } = useCommonStates();

  const location = useLocation();
  const navigate = useNavigate();

  const isResListPage = [
    APP_ROUTES.restaurants,
    `${APP_ROUTES.restaurants}/`
  ].includes(location.pathname);

  return (
    <AppHeaderWrapper>
      <AppHeaderTitle>
        <BackButton hideBack={isResListPage} />
        {pageTitle ||
          PAGE_TITLES[location.pathname as keyof typeof PAGE_TITLES]}
      </AppHeaderTitle>
      {loggedInUser ? (
        <UserProfileMenu />
      ) : (
        <div
          onClick={() => {
            navigate(APP_ROUTES.login);
          }}
        >
          LOGIN
        </div>
      )}
    </AppHeaderWrapper>
  );
};

export default AppHeader;
