import { API_URL, LOCAL_STORAGE_KEY_REFIX } from './constants';

export const setItemInLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getItemFromLocalStorage = (key: string, fallback: any) => {
  const item = localStorage.getItem(key);
  if (item) {
    // TODO: check object specifically for json parsing
    return typeof item === 'string' ? item : JSON.parse(item || '');
  }
  return fallback;
};

export const removeItemInLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const removeItemInLocalStorageWithAsliMenuPrefix = () => {
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith(LOCAL_STORAGE_KEY_REFIX)) {
      localStorage.removeItem(key);
    }
  });
};

export const convertToCapitalCase = (anyString: string) => {
  anyString = anyString.toLowerCase();
  const words = anyString.split(' ').join(' ').split('_');
  const capitalCaseWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const capitalCaseName = capitalCaseWords.join(' ');
  return capitalCaseName;
};

export const getSelectableList = (
  values: string[]
): { label: string; value: string }[] => {
  if (!values) return [];
  return values.map(value => ({
    label: value,
    value: value
  }));
};

export const getRestaurantStatusLabel = (status: string) => {
  switch (status) {
    case 'IN_REVIEW':
      return 'In Review';
    case 'APPROVED':
      return 'Approved';
    case 'REJECTED':
      return 'Rejected';
    default:
      return '';
  }
};

export const getImageUrl = (filePath: string) => {
  return `${API_URL}${filePath}`;
};

export const getResLogoImageUrl = (filePath: string) => `${API_URL}${filePath}`;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const NOOP = () => {};
