import styled from 'styled-components';
import { COLORS } from '../../common/theme';

export const RestaurantDetailsWrapper = styled.div`
  padding: 10px 20px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  height: 50px;
  box-shadow: 0 1px 3px ${COLORS.primaryBlue};
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MenuContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ActivationStatusContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  border-radius: 8px;
  margin: 10px 0 20px;
  gap: 10px;
  box-shadow: 0px 0px 10px
    ${({ isActive }) => (isActive ? COLORS.green : COLORS.red)};
`;

export const StatusContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ManageSubCta = styled.div`
  display: flex;
  gap: 5px;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  color: ${COLORS.primaryBlue};
  border: 1px solid ${COLORS.primaryBlue};
  font-size: 12px;
  font-weight: 600;
  width: auto;
  align-items: center;
`;

export const Status = styled.div<{ isActive: boolean }>`
  font-size: 12px;
  font-weight: 600;

  .statusText {
    font-size: 20px;
    color: ${({ isActive }) => (isActive ? COLORS.green : COLORS.red)};
  }

  strong {
    text-decoration: underline;
  }
`;

export const UpdateDetails = styled.div<{
  isApproved: boolean;
  isInReview: boolean;
  isRejected: boolean;
}>`
  font-size: 14px;
  .updateText {
    ${({ isApproved }) => (isApproved ? `color: ${COLORS.green};` : '')}
    ${({ isInReview }) => (isInReview ? `color: ${COLORS.yellow};` : '')}
    ${({ isRejected }) => (isRejected ? `color: ${COLORS.red};` : '')}
  }
`;

export const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  margin-bottom: 20px 0;
  place-items: stretch;
`;

export const Title = styled.div<{ sectionTitle?: boolean }>`
  font-size: 18px;
  font-weight: 600;
  ${({ sectionTitle }) =>
    sectionTitle
      ? `
  border-bottom: 1px solid ${COLORS.gray3};
  margin-bottom: 10px;
  
  `
      : ''}
`;

export const Value = styled.div`
  font-size: 18px;
  display: flex;
  word-break: break-word;
`;

export const SubscriptionNotice = styled.div`
  padding: 10px;
  border: 2px solid ${COLORS.red};
  background-color: ${COLORS.red}10;
  border-radius: 8px;
  margin-bottom: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubscriptionNoticeText = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.red};
  font-weight: 600;
`;

export const SubscriptionButton = styled.div`
  background-color: ${COLORS.red};
  color: ${COLORS.white};
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  width: 120px;
  box-shadow: 0px 0px 3px ${COLORS.red};
`;

export const RemarksContainer = styled.div`
  margin: 20px 0;

  .title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  ul {
    padding: 0 20px;
    margin: 0;
  }
`;
