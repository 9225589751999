import styled from 'styled-components';

export const TransferOwnershipFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;
export const TransferOwnershipDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  h4 {
    margin-bottom: 0;
  }
`;
