export const DISH_TYPES = [
  {
    label: 'Veg',
    value: 'VEG'
  },
  {
    label: 'Veg (Contains Egg)',
    value: 'CONTAINS_EGG'
  },
  {
    label: 'Non Veg',
    value: 'NON_VEG'
  }
];
