import { useEffect } from 'react';
import { useCommonActions } from '../store/commonStore';

export const useNoGlobalHeader = () => {
  const { setHideHeader } = useCommonActions();

  useEffect(() => {
    setHideHeader(true);

    return () => {
      setHideHeader(false);
    };
  }, [setHideHeader]);
};
