import { styled } from 'styled-components';

const APP_HEADER_HEIGHT = 50;
const APP_HEADER_HEIGHT_DESKTOP = 80;

export const AppRootWrapper = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const AppHeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: ${APP_HEADER_HEIGHT}px;
  z-index: 2;

  // media query for desktop
  @media (min-width: 768px) {
    height: ${APP_HEADER_HEIGHT_DESKTOP}px;
  }
`;

export const AppContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
