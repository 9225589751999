import styled from 'styled-components';
import Button from '../../components/Button/Button';
import { markQueryAsResolved } from '../../actions/actions';
import { useCommonActions } from '../../store/commonStore';
import { COLORS } from '../../common/theme';

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLORS.gray1};
  margin: 10px;
  margin-bottom: 20px;
`;

const QueryItem = ({ query }: { query: any }) => {
  const { setSnackbarMessage } = useCommonActions();

  const markResolved = async () => {
    await markQueryAsResolved(query.id);
    setSnackbarMessage('The query has been closed successfully.');
  };

  return (
    <ItemContainer>
      <div>{query.message}</div>
      <Button onClick={markResolved}>Mark as resolved</Button>
    </ItemContainer>
  );
};

export default QueryItem;
