import { StyledInput, StyledInputWrapper } from './TextInput.styles';
import { InputProps, TEXT_INPUT_THEMES } from './TextInput.types';
import { checkIfValidInput } from './TextInput.helpers';
import { IoIosSearch } from 'react-icons/io';

const TextInput = ({
  inputType,
  label,
  name,
  value,
  placeholder = '',
  onChange: onChangeProp,
  isRequired,
  noMargin,
  error,
  isDisabled,
  theme = TEXT_INPUT_THEMES.DEFAULT
}: InputProps) => {
  const onChange = (event: any) => {
    const newValue = event.target.value;
    const error = checkIfValidInput(newValue, inputType);

    onChangeProp?.(newValue, error);
  };

  const shouldShowError = isRequired ? error : error && value?.length;

  return (
    <StyledInputWrapper
      theme={theme}
      noMargin={noMargin}
      isDisabled={isDisabled}
    >
      {label && (
        <label htmlFor={name}>
          {label}
          {isRequired ? '*' : ''}
        </label>
      )}
      <StyledInput
        theme={theme}
        required={isRequired}
        name={name}
        error={error && value?.length ? true : false}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={isDisabled}
      />
      {shouldShowError ? (
        <div style={{ marginBottom: '10px', color: 'red', fontSize: 12 }}>
          {error}
        </div>
      ) : (
        <></>
      )}
      {theme === TEXT_INPUT_THEMES.SEARCH && (
        <IoIosSearch className="search-icon" />
      )}
    </StyledInputWrapper>
  );
};

TextInput.THEMES = TEXT_INPUT_THEMES;

export default TextInput;
