import React, { useEffect, useState } from 'react';
import { TimingsWrapper } from '../AddRestaurant.styles';
import TimePicker from '../../../components/TimePicker/TimePicker';
import AddDeleteIcon from '../../../components/AddDeleteIcon/AddDeleteIcon';
import { MAX_RESTAURANT_TIMINGS_COUNT } from '../../../common/constants';
import { v4 as uuid } from 'uuid';

type Timing = {
  startTime: string;
  endTime: string;
};

type TimingsProps = {
  timings: Timing[];
  onChange: (timings: Timing[]) => void;
  name: string;
  title: string;
  shouldShow: boolean;
};

const Timings = ({
  timings,
  onChange,
  name,
  title,
  shouldShow
}: TimingsProps) => {
  const [keyUUID, setKeyUUID] = useState('');

  useEffect(() => {
    setKeyUUID(uuid());
  }, []);

  const addTime = () => {
    onChange([
      ...timings,
      {
        startTime: '00:00',
        endTime: '00:00'
      }
    ]);
  };

  const removeTime = (index: number) => {
    timings.splice(index, 1);
    setKeyUUID(uuid());
    onChange([...timings]);
  };

  const onTimeChange = (value: any, index: number, key: keyof Timing) => {
    if (!timings[index]) {
      timings[index] = { startTime: '', endTime: '' };
    }
    timings[index][key] = value;
    onChange([...timings]);
  };

  const currentTimings = timings.map((t: any, index: number) => {
    return (
      <TimingsWrapper key={keyUUID + ' ' + index}>
        <label>{title}</label>
        <div className="timingsDiv">
          <div className="timingInputs">
            <TimePicker
              label=""
              name={`${name}_from_${index + 1}`}
              value={t?.startTime ? t?.startTime : null}
              onChange={value => {
                onTimeChange(value, index, 'startTime');
              }}
            />
            -- To --
            <TimePicker
              label=""
              name={`${name}_to_${index + 1}`}
              value={t?.endTime ? t?.endTime : null}
              onChange={value => onTimeChange(value, index, 'endTime')}
            />
          </div>
          <AddDeleteIcon
            index={index}
            list={timings}
            addCb={addTime}
            deleteCb={removeTime}
            listMaxOut={MAX_RESTAURANT_TIMINGS_COUNT}
          />
        </div>
      </TimingsWrapper>
    );
  });

  return shouldShow && timings ? (
    <div style={{ margin: '20px 0' }}>{currentTimings}</div>
  ) : (
    <></>
  );
};

export default Timings;
