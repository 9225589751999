import styled from 'styled-components';
import { APP_ROUTES } from '../../common/constants';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../common/theme';
import { RestaurantListApiSchemaItem } from '../../schema.types';
import { AiFillCaretRight } from 'react-icons/ai';
import { convertToCapitalCase } from '../../common/utils';

type ListItemProps = {
  resObject: RestaurantListApiSchemaItem;
};

export const ListItemWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 0 3px ${COLORS.primaryBlue};
  padding: 15px 10px;

  .resName {
    font-size: 24px;
    font-weight: 600;
    color: ${COLORS.black};
  }

  .areaName {
    font-size: 16px;
    font-weight: 400;
    color: ${COLORS.gray3};
    padding: 5px 0;
  }

  // Desktop
  @media (min-width: 768px) {
    margin: 5px;
    max-width: 350px;
  }
`;

const ListItem = (props: ListItemProps) => {
  const navigate = useNavigate();
  const { resObject } = props;
  const { resId, resName, areaName } = resObject;

  return (
    <ListItemWrapper
      onClick={() => navigate(`${APP_ROUTES.restaurants}/${resId}`)}
    >
      <div>
        <div className="resName">{resName}</div>
        <div className="areaName">{convertToCapitalCase(areaName)}</div>
      </div>
      <AiFillCaretRight color={COLORS.primaryBlue} />
    </ListItemWrapper>
  );
};

export default ListItem;
