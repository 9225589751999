import { styled } from 'styled-components';
import { ReactComponent as LogoWithName } from '../../assets/logo-with-name.svg';
import { COLORS } from '../../common/theme';

export const LoginWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const AppLogoWithName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  > div {
    margin-bottom: 6px;
    font-size: 24px;
    font-weight: bold;
  }

  .link {
    font-size: 14px;
    font-weight: 400;
    border: 1px solid ${COLORS.primaryBlue};
    padding: 6px 12px;
    border-radius: 4px;
    margin-top: 24px;
    box-shadow: 0 0 4px 0 ${COLORS.primaryBlue};
  }
`;

export const LogoWithNameIcon = styled(LogoWithName)`
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
`;

export const LoginContent = styled.div`
  padding: 12px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  // Desktop
  @media (min-width: 768px) {
    margin: auto;
    width: 400px;
    height: 250px;
  }
`;

export const Utilities = styled.div`
  .link {
    display: inline-block;
    border-bottom: 1px solid ${COLORS.black};
  }
`;
