import { AxiosResponse } from 'axios';

export const responseInterceptor = (response: AxiosResponse) => response;

export const errorInterceptor = (error: any) => {
  const status = error.response ? error.response.status : null;

  if (status === 401) {
    // Handle unauthorized access
  } else if (status === 403) {
    // Handle forbidden access
    // e.g. Fetching restaurant which user don't have access to
  } else if (status === 404) {
    // Handle not found errors
  } else {
    // Handle other errors
  }

  return Promise.reject(error);
};
