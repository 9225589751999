import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES, REDIRECT_LINKS } from '../../../common/constants';
import { useUserStates } from '../../../store/userStore';
import { PanelLinks } from './UserProfileMenu.styles';

export const UsefulLinks = ({ closePopup }: { closePopup?: () => void }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedInUser } = useUserStates();

  return (
    loggedInUser?.isPrivileged &&
    location.pathname !== APP_ROUTES.managementPanel && (
      <PanelLinks>
        <div
          className="link"
          onClick={() => {
            closePopup?.();
            navigate(APP_ROUTES.managementPanel);
          }}
        >
          Management Panel
        </div>
        <div
          className="link"
          onClick={() => {
            closePopup?.();
            navigate(APP_ROUTES.userQueries);
          }}
        >
          Your queries
        </div>
        <div
          className="link"
          onClick={() => {
            window.open(REDIRECT_LINKS.website, '_blank');
          }}
        >
          Visit our website
        </div>
        <div
          className="link"
          onClick={() => {
            window.open(REDIRECT_LINKS.privacy, '_blank');
          }}
        >
          Terms and Policies
        </div>
      </PanelLinks>
    )
  );
};
