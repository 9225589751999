import { InternalAxiosRequestConfig } from 'axios';
import { getItemFromLocalStorage } from '../common/utils';
import { APP_AUTH_TOKEN_KEY } from '../common/constants';

export const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  const authToken = getItemFromLocalStorage(APP_AUTH_TOKEN_KEY, undefined);
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  config.headers.isPartner = true;
  return config;
};
