import { useRef, useState } from 'react';
import {
  Footer,
  PopupCloseIcon,
  UserProfileDetailsPopup,
  UserProfileMenuWrapper,
  FooterContents,
  UserDetails,
  UserDetailsDesktop
} from './UserProfileMenu.styles';
import { RxAvatar } from 'react-icons/rx';
import { removeItemInLocalStorageWithAsliMenuPrefix } from '../../../common/utils';
import { APP_ROUTES } from '../../../common/constants';
import { CgClose } from 'react-icons/cg';
import { AiOutlineMenu } from 'react-icons/ai';
import { useOutsideClick } from '../../../global/useOutsideClick';
import Button from '../../Button/Button';
import ContactUs from './ContactUs';
import { COLORS } from '../../../common/theme';
import { useUserStates } from '../../../store/userStore';
import { UsefulLinks } from './UsefulLinks';
import { useNavigate } from 'react-router-dom';

const UserProfileMenu = () => {
  const navigate = useNavigate();

  const popupRef = useRef<HTMLDivElement>(null);
  const [supportForm, setSupportForm] = useState(false);

  const { loggedInUser } = useUserStates();

  const { isOpen, togglePopup } = useOutsideClick(popupRef);

  const signOut = () => {
    try {
      togglePopup(!isOpen);
      removeItemInLocalStorageWithAsliMenuPrefix();

      window.location.replace(`${window.location.origin}${APP_ROUTES.login}`);
    } catch (err) {
      console.log('Error Signing Out!', err);
    }
  };

  const userDetails = (
    <UserDetails>
      <RxAvatar className="avatar" size={50} />
      <div>{loggedInUser?.name}</div>
      <div>{loggedInUser?.phoneNumber}</div>
    </UserDetails>
  );

  const renderUserDetailsMobilePopup = () => {
    return (
      <UserProfileDetailsPopup isOpen={isOpen}>
        <PopupCloseIcon>
          <CgClose
            color={COLORS.primaryBlue}
            size={30}
            onClick={() => togglePopup(false)}
          />
        </PopupCloseIcon>
        {userDetails}
        <UsefulLinks closePopup={() => togglePopup(false)} />
        <Footer>
          <FooterContents>
            For queries,{' '}
            <strong onClick={() => setSupportForm(true)}>
              Send us a message
            </strong>
          </FooterContents>
          <Button className="logOutButton" onClick={signOut}>
            LOG OUT
          </Button>
        </Footer>
      </UserProfileDetailsPopup>
    );
  };

  const renderUserDetailsForDesktop = () => {
    return (
      <UserDetailsDesktop>
        <Button.Outlined onClick={() => navigate(APP_ROUTES.addRestaurant)}>
          Add Restaurant
        </Button.Outlined>
        <UsefulLinks />
        {userDetails}
        <Button className="logOutButton" onClick={signOut}>
          LOG OUT
        </Button>
      </UserDetailsDesktop>
    );
  };

  return (
    <UserProfileMenuWrapper ref={popupRef}>
      <AiOutlineMenu
        className="menuButton"
        size={30}
        onClick={() => togglePopup(!isOpen)}
      />
      {renderUserDetailsForDesktop()}
      {renderUserDetailsMobilePopup()}
      <ContactUs
        isOpen={supportForm}
        toggle={() => setSupportForm(!supportForm)}
      />
    </UserProfileMenuWrapper>
  );
};

export default UserProfileMenu;
