import Loader from '../../components/Loader/Loader';
import {
  ActivationStatusContainer,
  DetailsContainer,
  HeaderContent,
  HeaderTitle,
  HeaderWrapper,
  ManageSubCta,
  MenuContainer,
  RemarksContainer,
  RestaurantDetailsWrapper,
  Status,
  StatusContent,
  SubscriptionButton,
  SubscriptionNotice,
  SubscriptionNoticeText,
  Title,
  UpdateDetails,
  Value
} from './RestaurantDetails.styles';
import BackButton from '../../components/backButton/BackButton';
import { COLORS } from '../../common/theme';
import { MdEdit } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import { MdOutlineCardMembership } from 'react-icons/md';
import { convertToCapitalCase } from '../../common/utils';
import { useRestaurantDetails } from './useRestaurantDetails';

const RestaurantDetails = () => {
  const {
    resDetails,
    loading,
    popupRef,
    getApprovalText,
    editRestaurant,
    manageSubscription,
    openMenu
  } = useRestaurantDetails();

  if (loading) return <Loader isFullScreen />;
  return (
    <>
      <HeaderWrapper>
        <HeaderContent>
          <BackButton />
          <HeaderTitle>{resDetails.resName}</HeaderTitle>
        </HeaderContent>
        <MenuContainer ref={popupRef}>
          <MdOutlineRestaurantMenu
            size={20}
            color={COLORS.gray3}
            onClick={openMenu}
          />
          <MdEdit
            size={20}
            color={COLORS.primaryBlue}
            onClick={editRestaurant}
          />
        </MenuContainer>
      </HeaderWrapper>
      <RestaurantDetailsWrapper>
        {!resDetails.isSubscribed && (
          <SubscriptionNotice>
            <SubscriptionNoticeText>
              You are not subscribed. Subscribe to get listed on the ASLI MENU
              app.
            </SubscriptionNoticeText>
            <SubscriptionButton onClick={manageSubscription}>
              Subscribe Now
            </SubscriptionButton>
          </SubscriptionNotice>
        )}
        {resDetails.isSubscribed && (
          <ActivationStatusContainer isActive={resDetails.isActive}>
            <StatusContent>
              <Status isActive={resDetails.isActive}>
                <div className="statusText">
                  {resDetails.isActive ? 'Active' : 'Not Active'}
                </div>
                {resDetails.isActive && resDetails.resAppLink && (
                  <div>
                    View on <strong>Asli Menu</strong>
                  </div>
                )}
              </Status>
              <UpdateDetails
                isApproved={resDetails.state === 'APPROVED'}
                isInReview={resDetails.state === 'IN_REVIEW'}
                isRejected={resDetails.state === 'REJECTED'}
              >
                <div>
                  <strong>Update status:</strong>{' '}
                  <span className="updateText">
                    {getApprovalText(resDetails.state)}
                  </span>
                </div>
                {resDetails.updatedAt && (
                  <div>
                    <strong>Last Updated:</strong> <span>8 April, 24</span>
                  </div>
                )}
              </UpdateDetails>
            </StatusContent>
            <ManageSubCta onClick={manageSubscription}>
              <MdOutlineCardMembership color={COLORS.primaryBlue} />
              Manage Subscription
            </ManageSubCta>
          </ActivationStatusContainer>
        )}
        {resDetails.remarks?.length > 0 && (
          <RemarksContainer>
            <Title sectionTitle>Remarks from admin</Title>
            <ul>
              {resDetails.remarks.map((r: string) => (
                <li key={r}>{r}</li>
              ))}
            </ul>
          </RemarksContainer>
        )}
        <Title sectionTitle>Restaurant Details</Title>
        <DetailsContainer>
          <Title>Name</Title>
          <Value>{resDetails.resName}</Value>

          <Title>Cuisines</Title>
          <Value>{resDetails.cuisines.join(', ')}</Value>

          <Title>Location</Title>
          <Value>
            <a href={resDetails.location.gmapLink}>
              {resDetails.location.gmapLink + '(View on maps)'}
            </a>
          </Value>

          <Title>Address</Title>
          <Value>{resDetails.location.fullAddress}</Value>

          <Title>Area Name</Title>
          <Value>{convertToCapitalCase(resDetails.location.areaName)}</Value>

          <Title>Avg. price for one</Title>
          <Value>{resDetails.avgPrice}</Value>

          <Title>Phone numbers</Title>
          <Value>
            {resDetails.phoneNumbers.map((p: any) => String(p)).join(', ')}
          </Value>

          <Title>Is managed by owner?</Title>
          <Value>{resDetails.isManagedByOwner ? 'Yes' : 'No'}</Value>

          <Title>Restaurant Timings</Title>
          <div>
            {resDetails.restaurantTimings.map((t: any) => (
              <Value key={t.startTime}>
                {t.startTime} - {t.endTime}
              </Value>
            ))}
          </div>

          <Title>Dine In</Title>
          <Value>{resDetails.dineIn.enabled ? 'Yes' : 'No'}</Value>

          <Title>Takeaway</Title>
          <Value>{resDetails.takeaway.enabled ? 'Yes' : 'No'}</Value>

          <Title>Delivery</Title>
          <Value>{resDetails.delivery.enabled ? 'Yes' : 'No'}</Value>
        </DetailsContainer>
      </RestaurantDetailsWrapper>
    </>
  );
};

export default RestaurantDetails;
