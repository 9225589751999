import React from 'react';
import {
  TransferOwnershipDetailsWrapper,
  TransferOwnershipFormWrapper
} from './OptionsOverlays.styles';
import TextInput from '../../../../components/TextInput/TextInput';
import Button from '../../../../components/Button/Button';
import Loader from '../../../../components/Loader/Loader';
import { updateRestaurantOwnership } from '../../../../actions/actions';
import { useCommonActions } from '../../../../store/commonStore';
import Overlay from '../../../../components/overlay/Overlay';
import { capitalize } from 'lodash';

// Function to validate the comma separated list of phone numbers
const validateData = ({
  deleteFromNumbers,
  addForNumbers
}: {
  deleteFromNumbers: string;
  addForNumbers: string;
}) => {
  const deleteFromNumbersArray = deleteFromNumbers.split(',');
  const addForNumbersArray = addForNumbers.split(',');

  if (addForNumbersArray.length === 0 || deleteFromNumbersArray.length === 0) {
    return false;
  }

  // validate phone numbers
  const phoneRegex = /^\d{10}$/;
  for (let i = 0; i < deleteFromNumbersArray.length; i++) {
    if (!phoneRegex.test(deleteFromNumbersArray[i].trim())) {
      return false;
    }
  }

  for (let i = 0; i < addForNumbersArray.length; i++) {
    if (!phoneRegex.test(addForNumbersArray[i].trim())) {
      return false;
    }
  }

  return true;
};

const TransferOwnershipForm = ({
  selectedRes,
  onParentOverlayClose
}: {
  onParentOverlayClose: () => void;
  selectedRes: {
    resId: string;
  };
}) => {
  const { setSnackbarMessage } = useCommonActions();

  const [loading, setLoading] = React.useState(false);
  const [deleteFromNumbers, setDeleteFromNumbers] = React.useState('');
  const [addForNumbers, setAddForNumbers] = React.useState('');
  const [updateDetails, setUpdateDetails] = React.useState<null | Record<
    string,
    string[]
  >>(null);

  const onCloseDetailsOverlay = () => {
    onParentOverlayClose();
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    const isValid = validateData({
      deleteFromNumbers,
      addForNumbers
    });

    if (isValid) {
      const response = await updateRestaurantOwnership(selectedRes.resId, {
        deleteFromNumbers: deleteFromNumbers
          .split(',')
          .map(item => item.trim()),
        addForNumbers: addForNumbers.split(',').map(item => item.trim())
      });
      setUpdateDetails(response);
    } else {
      setSnackbarMessage('Invalid phone numbers');
    }
    setLoading(false);
  };

  return (
    <>
      <TransferOwnershipFormWrapper onSubmit={onSubmit} noValidate>
        <h3>Transfer Ownership Form</h3>
        <TextInput
          isRequired
          value={deleteFromNumbers}
          name="deleteFromNumbers"
          label="Phone numbers to remove ownership from:"
          placeholder="Enter Comma separated phone numbers"
          onChange={value => setDeleteFromNumbers(value)}
        />
        <TextInput
          isRequired
          value={addForNumbers}
          name="addForNumbers"
          label="Phone numbers of new owners:"
          placeholder="Enter Comma separated phone numbers"
          onChange={value => setAddForNumbers(value)}
        />
        <Button role="submit">
          {loading ? <Loader isSmall /> : <>Submit</>}
        </Button>
      </TransferOwnershipFormWrapper>
      <Overlay
        isFullScreen
        shouldShowClose
        isVisible={!!updateDetails}
        onClose={onCloseDetailsOverlay}
        placement={Overlay.PLACEMENTS.Bottom}
      >
        <TransferOwnershipDetailsWrapper>
          <h2>Transfer Ownership Details:</h2>
          {updateDetails &&
            Object.entries(updateDetails).map(([key, value]) =>
              value.length > 0 ? (
                <div key={key}>
                  <h4>{capitalize(key.split('_').join(' '))}:</h4>
                  <div>{value.join(', ')}</div>
                </div>
              ) : (
                <></>
              )
            )}
        </TransferOwnershipDetailsWrapper>
      </Overlay>
    </>
  );
};

export default TransferOwnershipForm;
