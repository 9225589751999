import React, { ComponentType } from 'react';

export type FormFieldProps = {
  name: string;
  value: string;
  onChange: (value: any) => void;
};

export const WithFormField = <P extends FormFieldProps>(
  WrappedComponent: ComponentType<P>
) => {
  const FormFieldWrapper: React.FC<P> = props => {
    return (
      <>
        <input type="hidden" name={props.name} value={props.value} />
        <WrappedComponent
          {...props}
          value={props.value}
          onChange={props.onChange}
        />
      </>
    );
  };

  return FormFieldWrapper;
};
