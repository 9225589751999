import Overlay from '../../../../components/overlay/Overlay';
import TransferOwnershipForm from './TransferOwnershipForm';

const OptionsOverlays = ({
  selectedOption,
  selectedRes,
  setSelectedOption,
  setSelectedRes
}: {
  selectedOption: string;
  selectedRes: any;
  setSelectedOption: (value: string) => void;
  setSelectedRes: (value: any) => void;
}) => {
  const onClose = () => {
    setSelectedOption('');
    setSelectedRes(null);
  };

  return (
    <Overlay
      isVisible={!!selectedOption && !!selectedRes}
      shouldShowClose
      placement={Overlay.PLACEMENTS.Bottom}
      onClose={onClose}
    >
      {selectedOption === 'UPDATE_OWNERSHIP' && (
        <TransferOwnershipForm
          selectedRes={selectedRes}
          onParentOverlayClose={onClose}
        />
      )}
    </Overlay>
  );
};

export default OptionsOverlays;
