import { API_ENDPOINTS } from './endpoints';
import axios from 'axios';
import { errorInterceptor, responseInterceptor } from './responseInterceptor';
import { requestInterceptor } from './requestInterceptor';
import { APP_AUTH_TOKEN_KEY } from '../common/constants';
import { setItemInLocalStorage } from '../common/utils';

axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(responseInterceptor, errorInterceptor);

export const getUser = async () => {
  try {
    const data = await axios.get(API_ENDPOINTS.user);
    return data.data;
  } catch (err) {
    // @ts-expect-error TODO
    if (err?.response?.status === 404) {
      return { error: { status: 404 } };
    }
    return { error: { status: 500 } };
  }
};

export const sendOTP = async (phoneNumber: string) => {
  const data = await axios.post(API_ENDPOINTS.sendOtp, {
    phoneNumber
  });
  return data.data;
};

export const verifyOTP = async (phoneNumber: string, otp: string) => {
  const data = await axios.post(API_ENDPOINTS.verifyOtp, {
    otp,
    phoneNumber
  });
  const response = data.data;

  setItemInLocalStorage(APP_AUTH_TOKEN_KEY, response.authToken);
  delete response.authToken;

  return response;
};

export const createUser = async (userObject: {
  name: string;
  phoneNumber: string;
}) => {
  const data = await axios.post(API_ENDPOINTS.user, userObject);
  return data.data;
};

export const getAllRestaurants = async () => {
  const data = await axios.get(API_ENDPOINTS.restaurant);
  return data.data;
};

export const addRestaurant = async (payload: any) => {
  const data = await axios.post(API_ENDPOINTS.restaurant, payload);
  return data.data;
};

export const saveRestaurantDetails = async (payload: any, resId: string) => {
  const data = await axios.post(`${API_ENDPOINTS.restaurant}${resId}`, payload);
  return data.data;
};

export const saveMenuDetails = async (payload: any) => {
  const data = await axios.post(
    `${API_ENDPOINTS.restaurant}${payload.restaurantId}/menu`,
    payload
  );
  return data.data;
};

export const getRestaurantChoices = async () => {
  try {
    const data = await axios.get(API_ENDPOINTS.restaurantChoices);
    return data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getRestaurantImages = async () => {
  try {
    const data = await axios.get(API_ENDPOINTS.restaurantLogoPaths);
    return data.data;
  } catch (err) {
    console.error(err);
  }
};

/**
 * V2 Actions ------------------------------------------------------------------------>
 */

export const getRestaurantList = async () => {
  const data = await axios.get(`${API_ENDPOINTS.restaurantV2}`);
  return data.data;
};

export const getRestaurantDetails = async (resId: string) => {
  const data = await axios.get(`${API_ENDPOINTS.restaurantV2}${resId}`);
  return data.data;
};

export const updateRestaurantDetails = async (resId: string, payload: any) => {
  const data = await axios.post(
    `${API_ENDPOINTS.restaurantV2}${resId}`,
    payload
  );
  return data.data;
};

export const getRestaurantMenu = async (resId: string) => {
  const data = await axios.get(`${API_ENDPOINTS.restaurantV2}${resId}/menu`);
  return data.data;
};

export const updateRestaurantMenu = async (resId: string, payload: any) => {
  const data = await axios.post(
    `${API_ENDPOINTS.restaurantV2}${resId}/menu`,
    payload
  );
  return data.data;
};

export const getRestaurantSubscription = async (resId: string) => {
  const data = await axios.get(
    `${API_ENDPOINTS.restaurantV2}${resId}/subscription`
  );
  return data.data;
};

export const createRestaurantSubscription = async (
  resId: string,
  payload: {
    planId: string;
  }
) => {
  const data = await axios.post(
    `${API_ENDPOINTS.restaurantV2}${resId}/subscription`,
    payload
  );
  return data.data;
};

/**
 * Management Panel APIs
 */
export const getAllRestaurantsForManagement = async () => {
  const data = await axios.get(API_ENDPOINTS.restaurantsForManagementPanel);
  return data.data;
};

export const getPlanCategories = async () => {
  const data = await axios.get(API_ENDPOINTS.planCategories);
  return data.data;
};

export const updateRestaurantFromManagementPanel = async (payload: any) => {
  const data = await axios.post(
    `${API_ENDPOINTS.restaurantsForManagementPanel}${payload.resId}`,
    payload
  );
  return data.data;
};

export const getManagementChoices = async () => {
  const data = await axios.get(API_ENDPOINTS.managementChoices);
  return data.data;
};

export const uploadRestaurantMenuExcel = async (payload: any) => {
  const data = await axios.post(
    API_ENDPOINTS.uploadRestaurantMenuExcel,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return data.data;
};

export const updateRestaurantOwnership = async (
  resId: string,
  payload: {
    deleteFromNumbers: string[];
    addForNumbers: string[];
  }
) => {
  const data = await axios.post(
    `${API_ENDPOINTS.restaurantsForManagementPanel}${resId}/update-ownership`,
    payload
  );
  return data.data;
};

/**
 * User queries APIs
 */
export const submitUserQuery = async (payload: {
  name: string;
  message: string;
}) => {
  const data = await axios.post(API_ENDPOINTS.userQueries, payload);
  return data.data;
};

export const fetchAllUserQueries = async () => {
  const data = await axios.get(API_ENDPOINTS.userQueries);
  return data.data;
};

export const markQueryAsResolved = async (queryId: string) => {
  const data = await axios.post(
    `${API_ENDPOINTS.userQueries}${queryId}/resolve`
  );
  return data.data;
};
