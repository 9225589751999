import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextInput from '../../components/TextInput/TextInput';
import { InputTypes } from '../../components/TextInput/TextInput.types';
import Button from '../../components/Button/Button';
import {
  LoginWrapper,
  LoginContent,
  AppLogoWithName,
  LogoWithNameIcon,
  Header,
  Content,
  Utilities
} from './Login.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { createUser, sendOTP, verifyOTP } from '../../actions/actions';
import qs from 'query-string';
import { useUserActions } from '../../store/userStore';
import { APP_ROUTES } from '../../common/constants';
import { unstable_batchedUpdates } from 'react-dom';
import { useCommonActions } from '../../store/commonStore';
import { useNoGlobalHeader } from '../../global/useNoGlobalHeader';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const setLoggedInUser = useUserActions().setLoggedInUser;
  const { setSnackbarMessage } = useCommonActions();

  useNoGlobalHeader();

  const [readyToRedirect, setReadyToRedirect] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [phone, setPhone] = useState({
    value: '',
    error: ''
  });
  const [name, setName] = useState({
    value: '',
    error: ''
  });
  const [newUser, setNewUser] = useState(false);
  const [enableOTP, setEnableOTP] = useState(false);
  const [otp, setOtp] = useState({
    value: '',
    error: ''
  });

  const redirectUser = useCallback(() => {
    setTimeout(() => {
      if (location.search) {
        const redirectUrl = qs.parse(location.search).redirect;
        navigate((redirectUrl as string).substring(1), {
          replace: true
        });
      } else {
        navigate(APP_ROUTES.restaurants, {
          replace: true
        });
      }
    });
  }, [location.search, navigate]);

  const onSendOTP = async () => {
    try {
      setIsButtonLoading(true);
      await sendOTP(`+91${phone.value}`);
      setSnackbarMessage('OTP sent successfully!');

      unstable_batchedUpdates(() => {
        setEnableOTP(true);
        setIsButtonLoading(false);
      });
    } catch (error) {
      setIsButtonLoading(false);
      setSnackbarMessage('Please refresh the page and try again');
    }
  };

  const onSubmitOtp = async () => {
    try {
      setIsButtonLoading(true);

      const result = await verifyOTP(`+91${phone.value}`, otp.value);

      unstable_batchedUpdates(() => {
        if (result?.isNewUser) {
          setNewUser(true);
        } else {
          setLoggedInUser(result.user);
          setReadyToRedirect(true);
        }
      });
      setIsButtonLoading(false);
    } catch (error) {
      setIsButtonLoading(false);
      setSnackbarMessage('Invalid OTP!');
    }
  };

  const signUpUser = async () => {
    try {
      setIsButtonLoading(true);
      // BE API to add user and navigate to next page
      const response = await createUser({
        name: name.value,
        phoneNumber: `+91${phone.value}`
      });

      unstable_batchedUpdates(() => {
        setLoggedInUser(response);
        setReadyToRedirect(true);
        setIsButtonLoading(false);
      });
    } catch (error) {
      setIsButtonLoading(false);
      setSnackbarMessage('Please refresh the page and try again');
    }
  };

  useEffect(() => {
    if (readyToRedirect) redirectUser();
  }, [readyToRedirect, redirectUser]);

  const renderBasicFlow = () => {
    return (
      <>
        <TextInput
          label="Phone number"
          name="phone"
          value={phone.value}
          error={phone.error}
          inputType={InputTypes.MOBILE}
          onChange={(value, error) => setPhone({ value, error })}
        />
        {!(enableOTP || newUser) ? (
          <Button
            isDisabled={phone.error.length > 0 || phone.value.length === 0}
            onClick={onSendOTP}
            isLoading={isButtonLoading}
          >
            Send OTP
          </Button>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderOTPFlow = () => {
    /* Below UI is enabled when the OTP is sent */
    return enableOTP ? (
      <>
        <TextInput
          label="Enter OTP"
          name="otp"
          value={otp.value}
          error={otp.error}
          inputType={InputTypes.OTP}
          onChange={(value, error) => setOtp({ value, error })}
        />
        {!newUser ? (
          <Button
            onClick={onSubmitOtp}
            isDisabled={phone.error.length > 0 || otp.error.length > 0}
            isLoading={isButtonLoading}
          >
            Submit
          </Button>
        ) : (
          <></>
        )}
      </>
    ) : (
      <></>
    );
  };

  const renderNewUserFlow = () => {
    /* The below UI will be enabled for Sign Up flow */
    return newUser ? (
      <>
        <TextInput
          label="Enter your name"
          name="userName"
          value={name.value}
          error={name.error}
          onChange={(value, error) => setName({ value, error })}
        />
        <Button
          onClick={signUpUser}
          isDisabled={
            phone.error.length > 0 ||
            otp.error.length > 0 ||
            name.error.length > 0
          }
          isLoading={isButtonLoading}
        >
          Submit
        </Button>
      </>
    ) : (
      <></>
    );
  };

  const goToWebsite = () => {
    window.open('https://aslimenu.com', '_blank');
  };

  return (
    <LoginWrapper>
      <Header>
        <AppLogoWithName>
          <LogoWithNameIcon onClick={goToWebsite} />
          <div>Welcome, Partner!</div>
          <div>Please login to continue.</div>
          <div className="link" onClick={goToWebsite}>
            Checkout our Website
          </div>
        </AppLogoWithName>
      </Header>
      <Content>
        <LoginContent>
          {renderBasicFlow()}
          {renderOTPFlow()}
          {renderNewUserFlow()}
        </LoginContent>
      </Content>
      <Utilities>
        <div>
          By clicking, I agree with{' '}
          <div
            className="link"
            onClick={() => {
              window.open('https://aslimenu.com/privacy', '_blank');
            }}
          >
            Terms and Policies
          </div>
        </div>
      </Utilities>
    </LoginWrapper>
  );
};

export default Login;
