import { useEffect, useState } from 'react';
import { AddMenuWrapper, Ctas } from './Menu.styles';
import MenuForm from './MenuForm/MenuForm';
import MenuList from './MenuList/MenuList';
import Loader from '../../components/Loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { getRestaurantMenu, saveMenuDetails } from '../../actions/actions';
import { APP_ROUTES } from '../../common/constants';
import { useCommonActions } from '../../store/commonStore';
import Overlay from '../../components/overlay/Overlay';

const AddMenuForm = ({
  showAddForm,
  setShowAddForm,
  onAddItem,
  latestCategorySelected
}: {
  showAddForm: boolean;
  setShowAddForm: (value: boolean) => void;
  onAddItem: (item: any) => void;
  latestCategorySelected: string;
}) => {
  const close = () => setShowAddForm(false);
  return (
    <Overlay
      isVisible={showAddForm}
      placement={Overlay.PLACEMENTS.Bottom}
      onClose={close}
    >
      <MenuForm
        onCancel={close}
        onChange={onAddItem}
        previousCategory={latestCategorySelected}
      />
    </Overlay>
  );
};

const EditMenuForm = ({
  editedItem,
  setEditedItem,
  onEdit
}: {
  editedItem: any;
  setEditedItem: (obj: any) => void;
  onEdit: (item: any, index: number) => void;
}) => {
  const close = () =>
    setEditedItem({
      item: null,
      index: -1
    });
  return (
    <Overlay
      isVisible={!!editedItem.item}
      placement={Overlay.PLACEMENTS.Bottom}
      onClose={close}
    >
      {editedItem.item && (
        <MenuForm
          item={editedItem.item}
          onCancel={close}
          onChange={(newItem: any) => {
            onEdit(newItem, editedItem.index);
            close();
          }}
        />
      )}
    </Overlay>
  );
};

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setSnackbarMessage } = useCommonActions();

  const [menu, setMenu] = useState<any[]>([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showMenuSubmit, setShowMenuSubmit] = useState(false);
  const [editedItem, setEditedItem] = useState<any>({
    item: null,
    index: -1
  });

  const resId = location.state?.resId;

  useEffect(() => {
    (async () => {
      const { menu: resMenu = [] } = await getRestaurantMenu(resId);
      setMenu(resMenu);
      setLoading(false);
    })();
  }, [resId]);

  const onAddItem = (item: any) => {
    setMenu([...menu, item]);
    setShowMenuSubmit(true);
    toggleForm();
  };

  const onChange = (updatedMenu: any[]) => {
    setMenu(updatedMenu);
    setShowMenuSubmit(true);
  };

  const toggleForm = () => {
    setShowAddForm(value => !value);
  };

  const goToRestaurantsPage = () => {
    navigate(APP_ROUTES.restaurants, {
      replace: true
    });
  };

  const saveMenu = async () => {
    setButtonLoading(true);
    const payload = {
      restaurantId: resId,
      menu
    };
    try {
      const response = await saveMenuDetails(payload);
      if (response) {
        setSnackbarMessage('Menu saved successfully!');
        goToRestaurantsPage();
      }
    } catch (err) {
      setSnackbarMessage('Error saving menu');
    } finally {
      setButtonLoading(false);
    }
  };

  const onEdit = (updatedItem: any, index: number) => {
    menu[index] = updatedItem;
    onChange([...menu]);
    setSnackbarMessage('Item updated successfully!');
  };

  // Go to home page if no restaurant is selected
  if (!resId) {
    goToRestaurantsPage();
  }

  if (loading) return <Loader isFullScreen />;

  const latestCategorySelected =
    menu.length > 0 ? menu[menu.length - 1]?.category : null;

  return (
    <AddMenuWrapper>
      <MenuList menu={menu} onChange={onChange} onEdit={setEditedItem} />
      <Ctas>
        {!showAddForm && (
          <Button.Outlined onClick={toggleForm}>Add Menu Item</Button.Outlined>
        )}
        {showMenuSubmit && (
          <Button isLoading={buttonLoading} onClick={saveMenu}>
            Save Menu
          </Button>
        )}
      </Ctas>

      <AddMenuForm
        showAddForm={showAddForm}
        setShowAddForm={setShowAddForm}
        onAddItem={onAddItem}
        latestCategorySelected={latestCategorySelected}
      />
      <EditMenuForm
        editedItem={editedItem}
        setEditedItem={setEditedItem}
        onEdit={onEdit}
      />
    </AddMenuWrapper>
  );
};

export default Menu;
