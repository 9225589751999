import { RouteObject } from 'react-router-dom';
import Login from '../containers/Login/Login';
import AppRoot from '../containers/AppRoot/AppRoot';
import Restaurants from '../containers/Restaurants/Restaurants';
import AddRestaurant from '../containers/AddRestaurant/AddRestaurant';
import Menu from '../containers/Menu/Menu';
import { APP_ROUTES } from '../common/constants';
import RestaurantsRoot from '../containers/restaurantsRoot/RestaurantsRoot';
import Subscription from '../containers/subscription/Subscription';
import RestaurantDetails from '../containers/restaurantDetails/RestaurantDetails';
import AdminPanel from '../containers/managementPanel/ManagementPanel';
import UserQueries from '../containers/userQueries/UserQueries';

const routes: RouteObject[] = [
  {
    path: APP_ROUTES.home,
    element: <AppRoot />,
    children: [
      {
        path: APP_ROUTES.login,
        element: <Login />
      },
      {
        path: APP_ROUTES.managementPanel,
        element: <AdminPanel />
      },
      {
        path: APP_ROUTES.userQueries,
        element: <UserQueries />
      },
      {
        path: APP_ROUTES.restaurants,
        element: <RestaurantsRoot />,
        children: [
          {
            index: true,
            element: <Restaurants />
          },
          {
            path: `${APP_ROUTES.restaurants}/:resId`,
            element: <RestaurantDetails />
          },
          {
            path: APP_ROUTES.addRestaurant,
            element: <AddRestaurant />
          },
          {
            path: APP_ROUTES.menu,
            element: <Menu />
          },
          {
            path: APP_ROUTES.subscription,
            element: <Subscription />
          }
        ]
      }
    ]
  }
];

export default routes;
