import { styled } from 'styled-components';
import { COLORS } from '../../common/theme';

export const AddMenuWrapper = styled.div`
  padding: 10px;
  height: 100%;
`;

export const Ctas = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  width: 100%;
  display: flex;
  gap: 10px;
  background: ${COLORS.white};
  box-shadow: 0 0 5px ${COLORS.primaryBlue};

  > * {
    flex: 1;
  }
`;
