export enum TEXT_INPUT_THEMES {
  DEFAULT = 'DEFAULT',
  SEARCH = 'SEARCH'
}

export type InputProps = {
  name: string;
  value: any;
  onChange: (value: any, error: string) => void;

  theme?: TEXT_INPUT_THEMES;
  label?: string;
  inputType?: InputTypes;
  placeholder?: string;
  isRequired?: boolean;
  noMargin?: boolean;
  error?: string;
  isDisabled?: boolean;
};

export enum InputTypes {
  MOBILE = 'mobile',
  OTP = 'otp',
  NUMBER = 'number'
}
