import styled from 'styled-components';
import { Placement } from './Overlay.types';
import { COLORS } from '../../common/theme';

const getHeight = (args: {
  isFullScreen: boolean;
  placement: Placement;
  isOpen: boolean;
}) => {
  if (args.isOpen) {
    if (args.isFullScreen) {
      return '100vh';
    } else {
      switch (args.placement) {
        case Placement.Bottom:
          return 'auto';
        case Placement.Right:
          return '100vh';
      }
    }
  }
  return '0';
};

const getPlacement = (placement: Placement) => {
  switch (placement) {
    case Placement.Bottom:
      return 'bottom: 0; left: 0;';
    case Placement.Right:
      return 'right: 0; top: 0;';
  }
};

export const OverlayContainer = styled.div<{
  isFullScreen: boolean;
  placement: Placement;
  isOpen: boolean;
}>`
  position: fixed;
  ${props => getPlacement(props.placement)}

  height: ${props => getHeight(props)};
  width: 100%;
  transition: height 0.3s ease;
  z-index: 20;
  overflow: hidden;
  background-color: ${COLORS.white};
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 19;
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.black}90;
`;

export const OverlayContent = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;
