export type ButtonProps = {
  children: any;
  onClick?: () => void | Promise<void>;
  className?: string;
  isDisabled?: boolean;
  role?: 'button' | 'submit' | 'reset';
  btnType?: BUTTON_TYPES;
  isLoading?: boolean;
  filled?: boolean;
};

export enum BUTTON_TYPES {
  FILLED = 'FILLED',
  OUTLINED = 'OUTLINED'
}
