import { useEffect, useState } from 'react';
import { fetchAllUserQueries } from '../../actions/actions';

export const useUserQueries = () => {
  const [queries, setQueries] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const queriesResponse = await fetchAllUserQueries();
      setQueries(queriesResponse);
      setLoading(false);
    })();
  }, []);

  return {
    loading,
    queries
  };
};
