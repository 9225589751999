import styled from 'styled-components';
import { COLORS } from '../../common/theme';
import { MOBILE_BREAKPOINT } from '../../common/constants';

export const UnauthorizedWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 100;
  background: ${COLORS.white};
  color: ${COLORS.red};
  font-size: 30px;
  font-weight: 600;
`;

export const RestaurantsWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: hidden;
`;

export const RestaurantsCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 2px solid ${COLORS.black};
  padding: 10px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

export const ResHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 10px;

  .resName {
    width: 90%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
`;

export const RestaurantsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  overflow: auto;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: block;
    width: 100%;
    height: 100%;
    border: 2px solid ${COLORS.black};
  }
`;

export const Row = styled.div<{ isHeader?: boolean }>`
  display: none;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    margin-bottom: 10px;
    ${({ isHeader }) =>
      isHeader && `font-weight: 600; border-bottom: 2px solid ${COLORS.black};`}
  }
`;

export const RowItem = styled.div`
  flex: 1;
  padding: 10px;
  word-break: break-word;
  display: inline-flex;
  align-items: center;

  > * {
    flex: 1;
  }

  @media (max-width: 768px) {
    display: inline-grid;
    grid-template-columns: 1fr 2fr;
    align-items: baseline;
  }
`;

export const Ctas = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: row;

  > * {
    flex: 1;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export const OptionsCta = styled.div`
  position: relative;
  text-align: center;
`;

export const OptionsDropdown = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) =>
    isVisible
      ? `
    display: block;
  `
      : `
    display: none;
  `}
  position: absolute;
  top: 110%;
  right: 0;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.black};
  z-index: 10;
  font-size: 14px;
  font-weight: 400;
  width: 150px;
`;

export const OptionItem = styled.div`
  padding: 10px 5px;
  cursor: pointer;
  width: 100%;
  text-align: center;

  &:hover {
    background: ${COLORS.gray4};
  }
`;
