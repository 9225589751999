import { styled } from 'styled-components';
import { COLORS } from '../../common/theme';
import { MOBILE_BREAKPOINT } from '../../common/constants';

export const RestaurantsWrapper = styled.div`
  padding: 0 10px;
  height: 100%;
`;

export const List = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 1px 2px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  // Desktop
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
  }
`;

export const AddCta = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 10px;
  width: 100%;
  background-color: ${COLORS.white};
  color: ${COLORS.primaryBlue};
  box-shadow: 0 0 5px ${COLORS.primaryBlue};

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;
