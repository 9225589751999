import styled from 'styled-components';
import { BiArrowBack } from 'react-icons/bi';
import { ReactComponent as Logo } from '../../assets/logo.svg';

export const AppHeaderBackIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    .backIcon {
      display: none;
    }
  }
`;

export const LogoIcon = styled(Logo)`
  width: 30px;
  height: 30px;
`;

const BackButton = ({ hideBack }: { hideBack?: boolean }) => {
  return (
    <AppHeaderBackIcon
      onClick={() => {
        window.history.back();
      }}
    >
      {hideBack ? <></> : <BiArrowBack size={14} />}
      <LogoIcon />
    </AppHeaderBackIcon>
  );
};

export default BackButton;
