import { useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { useCommonActions } from '../../../store/commonStore';
import TextInput from '../../TextInput/TextInput';
import Button from '../../Button/Button';
import {
  Background,
  ContactUsWrapper,
  PopupCloseIcon,
  Form
} from './ContactUs.styles';
import { submitUserQuery } from '../../../actions/actions';

const useContactUs = ({ toggle }: { toggle: (flag: boolean) => void }) => {
  const { setSnackbarMessage } = useCommonActions();

  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const onSubmit = async (e: any) => {
    e.preventDefault();

    await submitUserQuery({
      name,
      message
    });
    setSnackbarMessage('Thank you for reaching out. We will contact you soon!');
    toggle(false);
  };

  return {
    name,
    setName,
    message,
    setMessage,
    onSubmit
  };
};

const ContactUs = ({
  isOpen,
  toggle
}: {
  isOpen: boolean;
  toggle: (flag: boolean) => void;
}) => {
  const { name, setName, message, setMessage, onSubmit } = useContactUs({
    toggle
  });

  return (
    <>
      <Background shouldShow={isOpen} onClick={() => toggle(false)} />
      <ContactUsWrapper shouldShow={isOpen}>
        <Form onSubmit={onSubmit}>
          <PopupCloseIcon>
            <CgClose size={30} onClick={() => toggle(false)} />
          </PopupCloseIcon>
          <TextInput
            isRequired
            placeholder="Your name"
            name="name"
            value={name}
            onChange={value => setName(value)}
          />
          <textarea
            required
            placeholder="Type your message here"
            name="message"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <Button role="submit">Submit your Query</Button>
        </Form>
      </ContactUsWrapper>
    </>
  );
};

export default ContactUs;
