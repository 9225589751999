import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { DropzoneWrapper } from './FileDropzone.styles';

const FileDropzone = ({
  onDrop,
  isUploading
}: {
  onDrop: (acceptedFiles: File[]) => void;
  isUploading: boolean;
}) => {
  const onDropLocal = useCallback(
    (acceptedFiles: File[]) => {
      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropLocal
  });

  return (
    <DropzoneWrapper {...getRootProps()}>
      {isUploading ? (
        <p>Uploading...</p>
      ) : (
        <>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </>
      )}
    </DropzoneWrapper>
  );
};

export { FileDropzone };
